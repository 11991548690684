<template>
  <div>
    <div class="container-fluid">
      <div class="page-header">
        <div class="row">
          <div class="col-sm-6">
            <h3>{{ $t("companies") }}</h3>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a>{{ $t("management") }}</a>
              </li>
              <li class="breadcrumb-item active">
                {{ $t("companies") }}
              </li>
            </ol>
          </div>
          <div class="col-sm-6">
            <!-- Bookmark Start-->
            <div class="bookmark">
              <ul>
                <li>
                  <a
                    href="javascript:void(0)"
                    data-container="body"
                    data-bs-toggle="popover"
                    data-placement="top"
                    title=""
                    data-original-title="Tables"
                    ><i data-feather="inbox"></i
                  ></a>
                </li>
                <li>
                  <a
                    href="javascript:void(0)"
                    data-container="body"
                    data-bs-toggle="popover"
                    data-placement="top"
                    title=""
                    data-original-title="Chat"
                    ><i data-feather="message-square"></i
                  ></a>
                </li>
                <li>
                  <a
                    href="javascript:void(0)"
                    data-container="body"
                    data-bs-toggle="popover"
                    data-placement="top"
                    title=""
                    data-original-title="Icons"
                    ><i data-feather="command"></i
                  ></a>
                </li>
                <li>
                  <a
                    href="javascript:void(0)"
                    data-container="body"
                    data-bs-toggle="popover"
                    data-placement="top"
                    title=""
                    data-original-title="Learning"
                    ><i data-feather="layers"></i
                  ></a>
                </li>
                <li>
                  <a href="javascript:void(0)"
                    ><i class="bookmark-search" data-feather="star"></i
                  ></a>
                  <form class="form-inline search-form">
                    <div class="form-group form-control-search">
                      <input type="text" placeholder="Search.." />
                    </div>
                  </form>
                </li>
              </ul>
            </div>
            <!-- Bookmark Ends-->
          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="mt-10 col-md-12 p-0 project-list">
        <div class="card">
          <div class="row">
            <div class="col-md-6 p-0">
              <button
                class="btn btn-light" style="float: right;"
                @click="toggleFilter()"
              >
                <i class="fa fa-filter"></i>
              </button>
              <input class="form-control" type="text" style="max-width: calc(100% - 75px)" v-model="search" @change="fetchCompanies()" :placeholder="$t('search')">
            </div>
            <div class="col-md-6 p-0">
              <div class="form-group mb-0 me-0"></div>
              <a
                class="btn btn-primary-light mb-1"
                @click="$router.push('/create-company')"
                v-if="$rights.includes('CREATE_COMPANIES')"
              >
                <plus-square-icon class="custom-class"></plus-square-icon
                >{{ $t("create_new_company") }}</a
              >
              <a
                class="btn btn-primary-light mr-lg-2 mb-1"
                @click="toggleCompanyImportPopup()"
                v-if="$rights.includes('IMPORT_COMPANIES')"
              >
                <download-icon class="custom-class"></download-icon
                >{{ $t("import_new_company") }}</a
              >
            </div>
            <div v-show="showFilter" class="col-md-12 row" style="margin: 0px; padding-left: 0px;">
              <div class="col-md-8 pl-0">
                <b>{{ $t("period_last_activity") }}</b><br>
                <input
                  class="form-control digits"
                  style="max-width: 40%; display: inline;"
                  type="datetime-local"
                  v-model="filter.activityStartDate"
                />
                {{ $t("to") }}
                <input
                  class="form-control digits"
                  style="max-width: 40%; display: inline;"
                  type="datetime-local"
                  v-model="filter.activityEndDate"
                />
              </div>
              <div class="col-md-4">
                <b v-show="$rights.includes('TENANT_INDEPENDENCE')">{{ $t("tenant") }}</b><br>
                <div v-show="$rights.includes('TENANT_INDEPENDENCE')">
                  <!-- TODO: Add possibility to view viewable tenants from assigned roles -->
                  <v-autocomplete class="custom-autocomplete tenant-search" :items="tenants" v-model="filter.tenantId" hide-details="auto" dense :search-input.sync="tenantSearch" @keyup="fetchTenants(tenantSearch)" item-text='name' item-value='id' clearable>
                    <template v-slot:append>
                      <v-icon>fas fa-chevron-down</v-icon>
                    </template>
                    <template v-slot:selection="{item}">
                      {{item.name}}
                    </template>
                    <template v-slot:item="{item}">
                      {{item.name}}
                    </template>
                  </v-autocomplete>
                </div>
              </div>
              <div class="col-md-6 pl-0">
                <v-combobox
                    multiple
                    v-model="filter.reacheds"
                    :label="$t('reached')"
                    :items="possibleReacheds"
                    hide-details
                    chips
                    closable-chips
                    clearable
                    persistent-placeholder
                    item-text="title"
                    item-value="value"
                  ></v-combobox>
              </div>
              <div class="col-md-6">
                <v-combobox
                  multiple
                  v-model="filter.results"
                  :label="$t('result')"
                  :items="possibleResults"
                  hide-details
                  chips
                  closable-chips
                  clearable
                  persistent-placeholder
                  item-text="title"
                  item-value="value"
                ></v-combobox>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      v-show="$rights.includes('TENANT_INDEPENDENCE')"
                    >
                      {{ $t("tenant") }}
                    </th>
                    <th scope="col">{{ $t("designation") }}</th>
                    <th scope="col">{{ $t("address") }}</th>
                    <th scope="col">{{ $t("last_activity") }}</th>
                    <th scope="col">&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-show="companies == null || companies.length == 0">
                    <td colspan="5">{{ $t("no_entries") }}</td>
                  </tr>
                  <tr v-for="company in companies" :key="company.id">
                    <td v-show="$rights.includes('TENANT_INDEPENDENCE')">
                      {{ company.tenant != null ? company.tenant.name : "" }}
                    </td>
                    <td><a :id="company.designation" class="link" @click="$router.push('/edit-company?companyId=' + company.id)">{{ company.designation }}</a></td>
                    <td>{{ company.addressFormattedSingleLine }}</td>
                    <td>{{ $formatDateTime(company.lastProtocolEntryDateTime) }}</td>
                    <td>
                      <button
                        class="btn btn-light mr-2"
                        @click="$router.push('/edit-company?companyId=' + company.id)"
                      >
                        <i class="fa fa-pencil"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      style="
        position: absolute;
        bottom: 100px;
        left: 50%;
        height: 40px;
        width: 40px;
      "
      v-intersect.quiet="infiniteScrolling"
    ></div>
    <!-- Container-fluid Ends-->

    <div class="modal fade show" v-show="importCompanyPopup" tabindex="-1" aria-labelledby="exampleModalCenter" style="display: block; padding-right: 17px; background: rgba(0,0,0,.5);" aria-modal="true" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document" v-bind:class="{'with-table': importOutput.length >= 1 }">
        <div class="modal-content">
          <div class="modal-header">
            <h5 v-if="importOutput.length == 0" class="modal-title">{{$t('import_new_company')}}</h5>
            <h5 v-else class="modal-title">{{$t('import_results')}}</h5>
            
            <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close" data-bs-original-title="" title="" @click="toggleCompanyImportPopup()"></button>
          </div>
          <div class="modal-body">

            <div class="mb-3" v-if="$rights.includes('TENANT_INDEPENDENCE') && importOutput.length == 0">
              <label class="form-label" for="roleName">{{$t('tenant')}}</label>
              <v-autocomplete class="custom-autocomplete" :rules="rules" :items="tenants" v-model="importData.tenant" hide-details="auto" dense :search-input.sync="tenantSearch" @keyup="fetchTenants(tenantSearch)" item-text='name' item-value='id'>
                <template v-slot:append>
                  <v-icon>fas fa-chevron-down</v-icon>
                </template>
                <template v-slot:selection="{item}">
                  {{item.name}}
                </template>
                <template v-slot:item="{item}">
                  {{item.name}}
                </template>

              </v-autocomplete>
            </div>

            <div class="mb-3" v-if="$rights.includes('TENANT_INDEPENDENCE') && importOutput.length == 0">
              <label class="form-label" for="roleName">{{$t('file')}}</label>
              <v-file-input
              v-model="files"
              class="custom-fileinput"
                hide-details="auto"
                dense
                :rules="rules"
                accept=".csv"
              ></v-file-input>
            </div>

            <div v-if="importOutput.length >= 1" class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">{{ $t("type") }}</th>
                    <th scope="col">{{ $t("time") }}</th>
                    <th scope="col">{{ $t("description") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(output,index) in importOutput" :key="index">

                    <td v-if="output.type == 'INFO'"><info-icon class="custom-class" style="color:blue;"></info-icon></td><td v-else-if="output.type == 'WARNING'" style="color:yellow;"><alert-triangle-icon class="custom-class"></alert-triangle-icon></td><td v-else-if="output.type == 'ERROR'"><alert-circle-icon class="custom-class" style="color:red;"></alert-circle-icon></td>
                    <td>{{$formatDateTime(output.time)}}</td>
                    <td>{{output.message}}</td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
          <div class="modal-footer">
            <button @click="toggleCompanyImportPopup()" class="btn btn-secondary" type="button" data-bs-dismiss="modal" data-bs-original-title="" title="">{{ importOutput.length == 0 ? $t('cancel') : $t('close')}}</button>
            <button v-if="importOutput.length == 0" @click="saveImport()" class="btn btn-primary" type="button" data-bs-original-title="" title="">{{$t('save')}}</button>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import pagination from "./custom/pagination.vue";
import {
  MapPinIcon,
  PlusSquareIcon,
  ClipboardIcon,
  FileTextIcon,
  TrashIcon,
  EditIcon,
  DownloadIcon,
  InfoIcon,
  AlertTriangleIcon,
  AlertCircleIcon,
} from "vue-feather-icons";
import Upload from "./widget/Upload.vue";

export default {
  name: "Companies",
  components: {
    MapPinIcon,
    PlusSquareIcon,
    ClipboardIcon,
    FileTextIcon,
    TrashIcon,
    EditIcon,
    DownloadIcon,
    InfoIcon,
    AlertTriangleIcon,
    AlertCircleIcon,
    Upload
  },

  data() {
    return {
      page: 1,
      totalPages: 1,
      ElementPerPage: 50,
      sort: null,
      order: true,
      routes: {
        companies: "/api/company",
        tenants: "/api/tenant",
        filter: "/api/companies/filter/",
      },
      search: "",
      convertedTenants: [],
      tenantFilter: null,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      ],
      rules: [(value) => !!value || this.$t("required")],
      companies: [],
      roles: [],
      createCompanyPopup: false,
      importCompanyPopup: false,
      menu: false,
      createmode: true,
      showFilter: true,
      importData: {
        path: [],
        tenant: null,
      },
      importOutput: [],
      filter: {
        activityStartDate: null,
        activityEndDate: null,
        results: [],
        reacheds: [],
        tenantId: null,
      },
      possibleResults: [],
      possibleReacheds: [],

      tenantSearch: "",
      tenants: [],

      // Personal Data
      me: null,
    };
  },

  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    filter: {
      handler: function(){
        this.filterCompanies();
      },
      deep: true
    }
  },

  async mounted() {
    // console.log("mounted");
    await this.fetchEverything();
  },

  methods: {
    infiniteScrolling(entries, observer, isIntersecting) {
      if (isIntersecting) {
        setTimeout(() => {
          var _this = this;
          if (this.page < this.totalPages) {
            this.page++;
            this.$axios
              .get(this.routes.companies, {
                params: {
                  sort: this.sort,
                  order: this.order ? "ASC" : "DESC",
                  page: this.page - 1,
                  size: this.ElementPerPage,
                },
              })
              .then(function (response) {
                if (response.data.content.length > 1) {
                  response.data.content.forEach((item) =>
                    _this.companies.push(item)
                  );
                  _this.totalPages = response.data.totalPages;
                }
              })
              .catch(this.onError);
          }
        }, 500);
      }
    },
    previousPage() {
      this.page = this.page - 1;
      this.fetchCompanies();
    },
    nextPage() {
      this.page = this.page + 1;
      this.fetchCompanies();
    },
    onError(err) {
      if (
        err.response != null &&
        err.response.data != null &&
        err.response.data.message != null
      ) {
        this.$noty.error(err.response.data.message);
      } else {
        console.error(err);
      }
    },

    async fetchEverything(){
      await this.$reloadUser();
      this.fetchCompanies();
      this.fetchFilterObjects();
      this.fetchTenants();
    },
    toggleCompanyImportPopup(){
      this.importCompanyPopup = !this.importCompanyPopup;
      this.importOutput = [];
    },
    saveImport(){
      if(this.importData.tenant == null){
        this.$noty.error(this.$t("select_tenant_for_import"));
        return;
      }
      if(this.files == null){
        this.$noty.error(this.$t("select_file_for_import"));
        return;
      }
      var _this = this;
      const file = this.files;
      var formData = new FormData();
      formData.append("file", file);
      this.$axios
          .post("/api/company/import/" + this.importData.tenant,formData,{
            headers: { "Content-Type": "multipart/form-data" }
          })
          .then(function (response) {
            _this.importOutput = response.data;
            _this.files = null;
            _this.importData = {
              path: [],
              tenant: null,
            }
            _this.fetchCompanies();
          })
          .catch(this.onError);
    },
    fetchFilterObjects(){
      var _this = this;
      this.$axios
          .get("/api/company/filter/possible-result")
          .then(function (response) {
            _this.possibleResults = [];
            for (let i = 0; i < response.data.length; i++) {
              const e = response.data[i];
              _this.possibleResults.push({
                title: _this.$t(e),
                value: e,
              })
            }
          })
          .catch(this.onError);

      this.$axios
          .get("/api/company/filter/possible-reached")
          .then(function (response) {
            _this.possibleReacheds = [];
            for (let i = 0; i < response.data.length; i++) {
              const e = response.data[i];
              _this.possibleReacheds.push({
                title: _this.$t(e),
                value: e,
              })
            }
            // console.log(_this.possibleReacheds);
          })
          .catch(this.onError);
    },
    filterCompanies(){
      var _this = this;
      this.page = 1;

      let filterResult = [];
      let filterReached = [];

      for (let i = 0; i < this.filter.results.length; i++) {
        const e = this.filter.results[i];
        filterResult.push(e.value);
      }
      for (let i = 0; i < this.filter.reacheds.length; i++) {
        const e = this.filter.reacheds[i];
        filterReached.push(e.value);
      }

      const dtoFilter = {
        search: this.search,
        tenantId: this.filter.tenantId,
        lastActivityDateStart: this.filter.activityStartDate,
        lastActivityDateEnd: this.filter.activityEndDate,
        result: filterResult,
        reached: filterReached
      };
      this.$axios
        .post(this.routes.companies + "/filter", dtoFilter, {
          params: {
            sort: this.sort,
            order: this.order ? "ASC" : "DESC",
            page: this.page - 1,
            size: this.ElementPerPage,
          },
        })
        .then(function (response) {
          _this.companies = response.data.content;
          _this.totalPages = response.data.totalPages;
          _this.$forceUpdate();
        })
        .catch(this.onError);
    },
    fetchCompanies() {
      var _this = this;
      this.$axios
        .get(this.routes.companies, {
          params: {
            search: _this.search,
            tenant: _this.tenantFilter,
            sort: _this.sort,
            order: _this.order ? "ASC" : "DESC",
            page: _this.page - 1,
            size: _this.ElementPerPage,
          },
        })
        .then(function (response) {
          _this.companies = response.data.content;
          _this.totalPages = response.data.totalPages;
          _this.$forceUpdate();
        })
        .catch(this.onError);
    },
    fetchTenants(search = "", tenantToSet = null){
      if(search == null) return;
      var _this = this;
      this.$axios
        .get("/api/tenant?size=50&search=" + search)
        .then(function (response) {
          if(_this.tenants.length == 0){
            _this.tenants = response.data.content;
          }
          for (let i = 0; i < response.data.content.length; i++) {
              const tenant = response.data.content[i];
              if(_this.tenants.filter(t => { return t.name == tenant.name }).length == 0){
                _this.tenants.push(tenant);
              }
            }
          if(tenantToSet != null) _this.tenantContextId = tenantToSet;
          _this.$forceUpdate();
        })
        .catch(this.onError);
    },
    updatePage() {
      this.fetchCompanies();
    },
    updateVisibleCompanies() {
      var _this = this;
      this.companies = this.companies.slice(
        (_this.page = _this.ElementPerPage),
        (_this.page = _this.ElementPerPage) + _this.ElementPerPage
      );

      if (this.companies.length == 0 && this.page > 0) {
        this.updatePage(this.page - 1);
      }
    },
    createCompany() {
      var _this = this;

      var saveCompany = {};
      Object.assign(saveCompany, this.editedCompany);

      saveCompany.tenantId = saveCompany.customer != null ? saveCompany.customer.id : -1;
      saveCompany.companyname = saveCompany.mail;

      this.$axios
        .post(this.routes.companies, saveCompany)
        .then(function (response) {
          _this.$noty.success(_this.$t("company_created"));
          var company = response.data;
          if (
            _this.editedCompany.role != null &&
            _this.editedCompany.role.id != null
          ) {
            _this.addRoleToCompany(company.id, _this.editedCompany.role.id);
          }
          _this.addRightsToCompany(company.id, _this.editedCompany.rights);
          _this.resetEditedCompany();
          _this.createCompanyPopup = false;
          _this.page = 1;
          _this.fetchCompanies();
        })
        .catch(this.onError);
    },
    editCompany() {
      var _this = this;

      var saveCompany = {};
      Object.assign(saveCompany, this.editedCompany);

      saveCompany.tenantId = saveCompany.customer != null ? saveCompany.customer.id : -1;
      saveCompany.companyname = saveCompany.mail;

      this.$axios
        .put(this.routes.companies, saveCompany)
        .then(function (response) {
          var company = response.data;
          _this.$noty.success(_this.$t("company_updated"));
          if (_this.editedCompany.role != null && _this.editedCompany.role != null) {
            _this.addRoleToCompany(company.id, _this.editedCompany.role.id);
          }
          _this.addRightsToCompany(company.id, _this.editedCompany.rights);
          _this.resetEditedCompany();
          _this.createCompanyPopup = false;
          _this.fetchCompanies();
        })
        .catch(this.onError);
    },
    deleteCompany(companyid) {
      var _this = this;
      this.$axios
        .delete("api/companies/" + companyid)
        .then(function (response) {
          _this.$noty.success(_this.$t("company_deleted"));
          _this.fetchCompanies();
        })
        .catch(this.onError);
    },
    getJsonOptions() {
      const options = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      return options;
    },
    toggleFilter(){
      this.showFilter = !this.showFilter;
    },
  },
};
</script>

<style scoped>
.v-dialog > .v-card > .v-card__actions {
  padding: 8px 16px;
}
.custom-fileinput{
  border: 1px solid #eee;
  border-radius: 4px;
  
}
.custom-fileinput fieldset{
    border: none !important;
}
.with-table{
  max-width:700px !important;
}
.modal-footer{
  border:none !important;
}
</style>

