import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuetify from 'vuetify'

// import BootstrapVue from 'bootstrap-vue'
import axios from 'axios'
import HttpService from './Http.js'
import VueI18n from 'vue-i18n'
import messages from './assets/messages.json'
import VueNoty from 'vuejs-noty'
import 'vuetify/dist/vuetify.min.css'
import 'vue-cal/dist/vuecal.css'
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'

import VueApexCharts from 'vue-apexcharts'
import * as Icons from 'vue-feather-icons'

// Leaflet Map
import { LMap, LTileLayer, LMarker, LPolygon,LCircle,LPolyline,LControl } from 'vue2-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';


// WYSIWYG Editor
import { VueEditor } from "vue2-editor";

// Vue Components
import ResetPassword from './components/ResetPassword.vue'
import ForgotPassword from './components/ForgotPassword.vue'
import Users from './components/Users.vue'
import Tenants from './components/Tenants.vue'
import Roles from './components/Roles.vue'
import Login from './components/Login.vue'
import Register from './components/Register.vue'
import Imprint from './components/Imprint.vue'
import Dashboard from './components/Dashboard.vue'
import CreateTenant from './components/CreateTenant.vue'
import CreateUser from './components/CreateUser.vue'
import Companies from './components/Companies.vue'
import CreateCompany from './components/CreateCompany.vue'

// Widgets
import Contact from "./components/widget/Contact.vue"
import ConfirmDialog from "./components/widget/ConfirmDialog.vue"
import Share from "./components/widget/Share.vue"
import Upload from "./components/widget/Upload.vue"
import ImageUpload from "./components/widget/ImageUpload.vue"
import LanguagePicker from "./components/widget/LanguagePicker.vue"
import DatePicker from "./components/widget/DatePicker.vue"
import DateTimePicker from "./components/widget/DateTimePicker.vue"
import Fontawesomepicker from './components/widget/Fontawesomepicker.vue';
import LoadingButton from "./components/widget/LoadingButton.vue"


// Main Page
import App from './views/App.vue'

Vue.config.productionTip = false;

// Global Access Variables
Vue.prototype.$axios = HttpService;     // axios
Vue.prototype.$rights = [];             // User rights
Vue.prototype.$external = true;             // User rights
Vue.prototype.$languages = [];         // Function for finding flag by language String, e.g. GERMAN

window.Vue = Vue;

// Use base Components
Vue.use(Vuetify)
Vue.use(VueRouter)
Vue.use(VueEditor, /* { default global options} */)
Vue.component("vue-editor", VueEditor);
// Vue.use(BootstrapVue)

// Components
Vue.use(ResetPassword)
Vue.use(Users)
Vue.use(Tenants)
Vue.use(Login)
Vue.use(Register)
Vue.use(ResetPassword)

Vue.use(Companies)

// Widgets
Vue.use(Contact)
Vue.component("Contact", Contact);
Vue.use(ConfirmDialog)
Vue.component("ConfirmDialog", ConfirmDialog);
Vue.use(Share)
Vue.component("Share", Share);
Vue.use(Upload)
Vue.component("Upload", Upload);
Vue.use(ImageUpload)
Vue.component("ImageUpload", ImageUpload);
Vue.use(Fontawesomepicker)
Vue.component("Fontawesomepicker", Fontawesomepicker);
Vue.use(DatePicker)
Vue.component("DatePicker", DatePicker);
Vue.use(LanguagePicker)
Vue.component("LanguagePicker", LanguagePicker);
Vue.use(DateTimePicker)
Vue.component("DateTimePicker", DateTimePicker);
Vue.use(LoadingButton)
Vue.component("LoadingButton",LoadingButton)

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

// Leaflet for OpenRouteService Map
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-polygon', LPolygon);
Vue.component('l-circle',LCircle)
Vue.component('l-polyline',LPolyline)
Vue.component('l-control',LControl)

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
   iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
   iconUrl: require('leaflet/dist/images/marker-icon.png'),
   shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// Language
Vue.use(VueI18n)
const i18n = new VueI18n({
    locale: 'de',
    messages,
});


// Vuetify
const vuetifyOpts = {
    theme: {
        themes: {
            light: {
                primary: "#F60052",
                secondary: "#C1EDCC",
                accent: "#82B1FF",
                error: "#FF5252",
                info: "#2196F3",
                success: "#4CAF50",
                warning: "#FB8C00",
                grey: "#2f2f2f",
            }
        }
    }
};
const vuetify = new Vuetify(vuetifyOpts);


// Vue Router
const router = new VueRouter({
    routes: [
        // {
        //     path: "/", component: Login
        // },
        { path: "/login", component: Login, props: route => ({ routingTarget: route.query.routingTarget }) },
        { path: "/", component: Login },
        { path: "/register", component: Register },
        { path: "/dashboard", component: Dashboard },
        { path: "/users", component: Users },
        { path: "/tenants", component: Tenants },
        { path: "/roles", component: Roles },
        { path: "/create-tenant", component: CreateTenant },
        { path: "/edit-tenant", component: CreateTenant, props: route => ({ tenantId: route.query.tenantId }) },
        { path: "/create-user", component: CreateUser },
        { path: "/edit-user", component: CreateUser, props: route => ({ userId: route.query.userId }) },
        { path: "/create-company", component: CreateCompany },
        { path: "/edit-company", component: CreateCompany, props: route => ({ companyId: route.query.companyId }) },
        { path: "/forgot-password", component: ForgotPassword },
        { path: "/reset-password", component: ResetPassword, props: route => ({ token: route.query.token }) },
        { path: "/imprint", component: Imprint },
        { path: "/companies", component: Companies, name: "Companies" },
    ],
    mode: "history"
});

Object.defineProperty(Vue.prototype, '$locale', {
    get: function () {
        return i18n.locale
    },
    set: function (locale) {
        i18n.locale = locale
    }
});


// Notifications
Vue.use(VueNoty, {
    timeout: 6000,
    progressBar: true,
    killer: true,
    layout: 'topRight'
});

// Global Methods
Vue.prototype.$formatDate = function(dateStr){
    if(dateStr == null || dateStr == "") return "";
    return new Date(dateStr).toLocaleDateString(navigator.languages, { year: 'numeric', month: '2-digit', day: '2-digit' });
}

Vue.prototype.$formatDateTime = function(dateStr){
    if(dateStr == null || dateStr == "") return "";
    return new Date(dateStr).toLocaleDateString(navigator.languages, { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' });
}

Vue.prototype.$finalize = function(){
    progressIndicator.hidden = true;
}

Vue.prototype.$routerPush = function(path, right, elsePath = null){
    if(this.$rights.includes(right)){
        this.$router.push(path);
    }else if(elsePath != null){
        this.$router.push(elsePath);
    }
}

Vue.prototype.$routerBack = function(){
    this.$router.go(-1);
}

var allRights = [];
var allVariableRights = [];

Vue.prototype.$reloadUser = async function(){
    var _this = this;
    var response = await this.$axios.get("/api/user/me");
    const user = response.data;
    Vue.prototype.$user = Vue.observable({ data: user, setValue(val) {this.data = val;}, getValue() {return this.data;}});
    Vue.prototype.$rights = user.allRights;
    Vue.prototype.$variableRights = user.allVariableRights;
    allRights = user.allRights;
    allVariableRights = user.allVariableRights;
    _this.$forceUpdate();
}

Vue.prototype.$fetchCurrentUser = async function(){
    return await this.$axios.get("/api/user/me");
}

Vue.prototype.$hasVariableRight = async function(basicRight, bisCategoryId){
    if(allVariableRights.length == 0){
        
    }else{
        for (let i = 0; i < allVariableRights.length; i++) {
            const variableRight = allVariableRights[i];
            if(variableRight.basicRight == basicRight && variableRight.bisCategoryId == bisCategoryId) return true;
        }
        return false;
    }
}

Vue.prototype.$onError = function(err){
    progressIndicator.hidden = true;
    if (
        err.response != null &&
        err.response.data != null
      ) {
        Vue.prototype.$noty.error(err.response.data);
      } else {
        console.error(err);
      }
}

Vue.directive('blur', {
    inserted: function (el) {
      el.onfocus = (ev) => ev.target.blur()
    }
  });

new Vue({
    render: h => h(App),
    components: { App,
        Contact,
        ConfirmDialog,
        Share,
        LMap, LTileLayer, LMarker, LPolygon,LCircle,LPolyline,LControl },
    i18n,
    router,
    vuetify,
    watch:{
        $route (to, from){
            window.scrollTo(0, 0);
        }
    },
    beforeCreate: function(){
        if(window.location.href.includes("?lang")){
            window.location.href = window.location.href.substring(0, window.location.href.indexOf("?"));
        }
        if(window.location.href.includes("%3Flang")){
            window.location.href = window.location.href.substring(0, window.location.href.indexOf("%3Flang"));
        }
        Vue.prototype.$rights = [];

        var _this = this; 
        this.$axios.get("/api/user/me").then(function(response){
            const user = response.data;
            Vue.prototype.$user = Vue.observable({ data: user, setValue(val) {this.data = val;}, getValue() {return this.data;}});
            Vue.prototype.$rights = user.allRights;
            Vue.prototype.$variableRights = user.allVariableRights;
            allRights = user.allRights;
            allVariableRights = user.allVariableRights;
            console.log(Vue.prototype.$rights);
        })
        .catch(function(error){
            console.error(error);

            _this.checkWindowLocationAndRouteToLogin(false);
        });
    },
    beforeMount: function () {
        const routingTarget = "/companies";
        // const routingTarget = allRights.includes("VIEW_DASHBOARD") ? "/dashboard" : "/users";
        this.checkWindowLocationAndRouteToLogin(true, routingTarget);
        var _this = this;

        var _this = this;
        this.$axios.get("/api/language/me").then(function(response){ 
            var selectedlanguage = response.data.shortForm;
            Vue.prototype.$language = selectedlanguage;
            _this.$locale = selectedlanguage.toLowerCase();
        }).catch(function(error){
            console.error(error);
        });

        this.$axios.get("/api/language/all").then(function(response){ 
            Vue.prototype.$languages = response.data;
            const languages = response.data;
            const lnlist = [];
            for (var i in languages){
                var language = {
                    key: languages[i].language,
                    name: _this.$t(languages[i].language),
                    flag: languages[i].flag,
                    shortForm: languages[i].shortForm
                };
                lnlist.push(language);
            }
            Vue.prototype.$selectableLanguages = lnlist;
        }).catch(function(error){
            console.error(error);
        });
    },
    methods: {
        setUser(user) {
            this.user = user;
        },

        checkWindowLocationAndRouteToLogin(loggedIn = false, routingTarget = "/companies"){
            var location = window.location.href;
            if(location.startsWith("http://") || location.startsWith("https://")) location = location.substring(location.indexOf("//") + 2);
            const originalLocation = location;
            if(location.includes("?")) location = location.substring(0, location.indexOf("?"));
            console.log(location);
            const unauthRoutes = ["/login", "/forgot-password", "/reset-password", "/register"];
            const authRoutes = [];

            const allowedRoutes = loggedIn ? authRoutes.concat(unauthRoutes) : unauthRoutes;
            const unallowedRoutes = loggedIn ? unauthRoutes : authRoutes;
            
            var routeAllowed = loggedIn ? true : false;
            console.log(allowedRoutes);


            // Check allowedRoutes
            for (let i = 0; i < allowedRoutes.length; i++) {
                const allowedRoute = allowedRoutes[i];
                if(location.includes(allowedRoute)){
                    routeAllowed = true;
                    break;
                }
            }
            if(!location.includes("/")) return; // Start Page always allowed
            if(routeAllowed) return;

            const unallowedRoute = location.substring(location.indexOf("/"));
            if(unallowedRoute == "/") return; // Start Page always allowed

            console.log(unallowedRoute);
            const unauthRoutingTarget = originalLocation.substring(originalLocation.indexOf("/"));
            if(loggedIn){
                this.$router.push(routingTarget);
            }else{
                this.$router.push("/login?routingTarget=" + unauthRoutingTarget);
            }
        },
    }
}).$mount("#app");
