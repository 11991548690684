<template>
  <div>
        <!-- Container-fluid starts-->
        <div class="container-fluid dashboard-default-sec">
          <div class="row">
            <div class="col-xl-5 box-col-12 des-xl-100" style="padding-top: 0px"> 
              <div class="row">
                <div class="col-xl-12 col-md-6 box-col-6 des-xl-50">
                  <div class="card">
                    <div class="card-body text-center">
                      <h3>{{ salutation }} {{ $user != null ? $user.firstname + " " + $user.lastname : "" }}</h3>
                      <p>{{ simpleStats.newCasesLast24Hours == 0 ? "In den letzten 24h sind keine neue Fälle gemeldet worden." : simpleStats.newCasesLast24Hours == 1 ? "In den letzten 24h ist ein neuer Fall gemeldet worden." : "In den letzten 24h sind " + simpleStats.newCasesLast24Hours + " neue Fälle gemeldet worden." }}</p>
                      <a class="btn btn-primary-light" @click="$router.push('cases')">Zur Fallverwaltung</a>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-md-3 col-sm-6 box-col-3 des-xl-25 rate-sec">
                  <div class="card income-card">                                 
                    <div class="card-body text-center">                                  
                      <div class="round-box" v-bind:class="{'primary' : simpleStats.deltaNewCasesThisWeek > 0, 'bg-error' : simpleStats.deltaNewCasesThisWeek < 0}">
                        <div style="padding-left: 7px;"><i style="font-size: 32px" v-bind:class="{'toprightarrow': simpleStats.deltaNewCasesThisWeek > 0, 'bottomrightarrow': simpleStats.deltaNewCasesThisWeek < 0, 'rightarrow': simpleStats.deltaNewCasesThisWeek == 0}" class="fas fa-arrow-up me-2"></i></div>
                      </div>
                      <h5>{{ simpleStats.newCasesThisWeek }}</h5>
                      <p>Neue Fälle in einer Woche</p><a class="btn-arrow" style="color: #444 !important;" v-bind:class="{'arrow-primary' : simpleStats.deltaNewCasesThisWeek > 0, 'error-color' : simpleStats.deltaNewCasesThisWeek < 0}" href="javascript:void(0)"><i v-bind:class="{'toprightarrow': simpleStats.deltaNewCasesThisWeek > 0, 'bottomrightarrow': simpleStats.deltaNewCasesThisWeek < 0, 'rightarrow': simpleStats.deltaNewCasesThisWeek == 0}" style="color: #444 !important;" class="fa fa-arrow-up me-2"></i>{{ simpleStats.deltaNewCasesThisWeek }}% </a>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-md-3 col-sm-6 box-col-3 des-xl-25 rate-sec">
                  <div class="card income-card">                                    
                    <div class="card-body text-center">                                  
                      <div class="round-box" v-bind:class="{'primary' : simpleStats.deltaFinishedCasesThisWeek > 0, 'bg-error' : simpleStats.deltaFinishedCasesThisWeek < 0}">
                        <div style="padding-left: 7px;"><i style="font-size: 32px" v-bind:class="{'toprightarrow': simpleStats.deltaFinishedCasesThisWeek > 0, 'bottomrightarrow': simpleStats.deltaFinishedCasesThisWeek < 0, 'rightarrow': simpleStats.deltaFinishedCasesThisWeek == 0}" class="fas fa-arrow-up me-2"></i></div>
                      </div>
                      <h5>{{ simpleStats.finishedCasesThisWeek }}</h5>
                      <p>Abgeschlossene Fälle in einer Woche</p><a class="btn-arrow" style="color: #444 !important;" v-bind:class="{'arrow-primary' : simpleStats.deltaFinishedCasesThisWeek > 0, 'error-color' : simpleStats.deltaFinishedCasesThisWeek < 0}" href="javascript:void(0)"><i v-bind:class="{'toprightarrow': simpleStats.deltaFinishedCasesThisWeek > 0, 'bottomrightarrow': simpleStats.deltaFinishedCasesThisWeek < 0, 'rightarrow': simpleStats.deltaFinishedCasesThisWeek == 0}" style="color: #444 !important;" class="fa fa-arrow-up me-2"></i>{{ simpleStats.deltaFinishedCasesThisWeek }}% </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-7 box-col-12 des-xl-100 dashboard-sec" style="padding-top: 0px;">
              <div class="card income-card">
                <div class="card-header">
                  <div class="header-top d-sm-flex align-items-center">
                    <h5>Neue Fälle im Zeitverlauf</h5>
                    <div class="center-content">
                      <p class="d-sm-flex align-items-center"><span class="font-primary m-r-10 f-w-700">{{ simpleStats.newCasesThisYear }} Fälle</span><i class="fa fa-arrow-up m-r-10" v-bind:class="{'toprightarrow': simpleStats.deltaNewCasesThisYear > 0, 'bottomrightarrow': simpleStats.deltaNewCasesThisYear < 0, 'rightarrow': simpleStats.deltaNewCasesThisYear == 0}" style="color: #444 !important;"></i>{{ simpleStats.deltaNewCasesThisYear }}% {{ simpleStats.deltaTextNewCasesThisYear }}</p>
                    </div>
                  </div>
                </div>
                <div class="card-body p-0" style="height: 400px">
                  <apexcharts v-if="casesDiagramLoaded" height="100%" :options="caseOverviewOptions" :series="caseOverviewOptions.series"></apexcharts>
                  <div id="chart-timeline-dashbord"></div>
                  <div v-show="newestCases.length == 0" style="position: absolute; top: 100px; width: 100%; text-align: center;">
                    Es wurden noch keine Fälle gemeldet.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-12 box-col-12 des-xl-100">
              <div class="row">
                <div class="col-xl-4 col-25 box-col-4 des-xl-25">
                  <div class="card">
                    <div class="card-header">
                      <div class="header-top d-sm-flex align-items-center">
                        <h5>Alle Fälle Überblick</h5>
                      </div>
                    </div>
                    <div class="card-body p-0">
                      <apexcharts v-if="otherStats.length > 0" :options="userOverviewOptions.options" :series="userOverviewOptions.options.series"></apexcharts>
                      <div id="chart-dashbord"></div>
                      <div class="code-box-copy">
                        <button class="code-box-copy__btn btn-clipboard" data-clipboard-target="#sell-overview" title="Copy"><i class="icofont icofont-copy-alt"></i></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-8 col-75 box-col-8 des-xl-75">
                  <div class="card latest-update-sec">
                    <div class="card-header">
                      <div class="header-top d-sm-flex align-items-center">
                        <h5>News Ticker</h5>
                        <!-- <div class="center-content">
                          <ul class="week-date">
                            <li class="font-primary">Heute</li>
                            <li>Monat</li>
                          </ul>
                        </div> -->
                      </div>
                    </div>
                    <div class="card-body">
                      <div v-show="newestCases.length == 0">
                        Es wurden noch keine Fälle gemeldet.
                      </div>
                      <div class="table-responsive">
                        <table class="table table-bordernone">
                          <tbody>
                            <tr v-for="cs in newestCases" :key="cs.id">
                              <td>
                                <div class="media">
                                  <div class="media-body"><span style="display: inline-block;width: 300px;white-space: nowrap;overflow: hidden !important;text-overflow: ellipsis;">#{{ cs.caseNumber }} {{ cs.description }}</span>
                                    <p>{{ $formatDateTime(cs.createdAt) }}</p>
                                  </div>
                                </div>
                              </td>
                              <td style="text-align: center;padding-top: 17px;">{{cs.assignee != null ? cs.assignee.fullname : ""}}</td>
                              <td style="text-align: center;padding-top: 15px;"><span class="badge" style="font-size: 10px;" v-bind:class="{'badge-primary': cs.state == 'FINISHED' || cs.state == 'CANCELLED' || cs.state == 'REJECTED', 'badge-info': cs.state == 'CREATED', 'badge-secondary': cs.state == 'IN_PROGRESS' }">{{ $t("STATE_" + cs.state) }}</span></td>
                              <td><a href="javascript:void(0)" @click="$router.push('/edit-case?caseId=' + cs.id)">Zum Fall</a></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="code-box-copy">
                        <button class="code-box-copy__btn btn-clipboard" data-clipboard-target="#latest-update-sec" title="Copy"><i class="icofont icofont-copy-alt"></i></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-8 col-75 box-col-8 des-xl-75">
                  <div class="card latest-update-sec">
                    <div class="card-header">
                      <div class="header-top d-sm-flex align-items-center">
                        <h5>Fälle mit Bearbeitungsfristen</h5>
                      </div>
                    </div>
                    <div class="card-body">
                      <div v-show="casesWithDeadlines.length == 0">
                        Es liegen keine Fälle mit kurz bevorstehender oder abgelaufener Bearbeitungsfrist vor.
                      </div>
                      <div class="table-responsive">
                        <table class="table table-bordernone">
                          <tbody>
                            <tr v-for="cs in casesWithDeadlines" :key="cs.id">
                              <td>
                                <div class="media">
                                  <div class="media-body"><span style="display: inline-block;width: 300px;white-space: nowrap;overflow: hidden !important;text-overflow: ellipsis;">#{{ cs.caseNumber }} {{ cs.description }}</span>
                                    <p v-bind:class="{'error-color': new Date() > new Date(cs.stateChangedDeadline)}">Bearbeitungsfrist: {{ cs.stateChangedDeadlineFormatted }}</p>
                                  </div>
                                </div>
                              </td>
                              <td style="text-align: center;padding-top: 17px;">{{cs.assignee != null ? cs.assignee.fullname : ""}}</td>
                              <td style="text-align: center;padding-top: 15px;"><span class="badge" style="font-size: 10px;" v-bind:class="{'badge-primary': cs.state == 'FINISHED' || cs.state == 'CANCELLED' || cs.state == 'REJECTED', 'badge-info': cs.state == 'CREATED', 'badge-secondary': cs.state == 'IN_PROGRESS' }">{{ $t("STATE_" + cs.state) }}</span></td>
                              <td><a href="javascript:void(0)" @click="$router.push('/edit-case?caseId=' + cs.id)">Zum Fall</a></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="code-box-copy">
                        <button class="code-box-copy__btn btn-clipboard" data-clipboard-target="#latest-update-sec" title="Copy"><i class="icofont icofont-copy-alt"></i></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-12 col-100 box-col-12 des-xl-100"></div> <!-- Divider -->
                <div v-for="surveydata in surveysData" :key="surveydata.survey.id" class="col-xl-4 col-md-6 col-12 col-25 box-col-4 des-xl-25">
                  <div class="card" v-if="$rights.includes('BIS_EVALUATE_SURVEYS')">
                    <div class="card-header pb-0">
                      <h5>Ergebnis - {{ surveydata.survey.name }}</h5>
                    </div>
                    <div class="card-body pa-1">
                      <div>
                        <apexcharts
                          v-if="surveyDataLoaded"
                          height="350"
                          width="100%"
                          :options="surveydata.chartOptions"
                          :series="surveydata.data"
                        ></apexcharts>
                      </div><br>
                      <!-- <h5>Anmerkungen der Teilnehmenden</h5>
                      <div class="col" style="max-height: 300px; overflow-y: auto;">
                      <div class="mb-3 note" v-for="result in results" :key="result.id">
                        <p>{{ result.answer }}</p>
                      </div> -->
                    </div>  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Container-fluid Ends-->
      </div>
</template>

<script>

import VueApexCharts from 'vue-apexcharts'
import { SettingsIcon,LogOutIcon,HomeIcon,AlignCenterIcon,AlertCircleIcon,ClipboardIcon,UsersIcon,CopyIcon,MaximizeIcon,StarIcon,BellIcon,MessageSquareIcon,GridIcon,BriefcaseIcon       } from 'vue-feather-icons'

export default {

  components: {
  apexcharts: VueApexCharts,
  SettingsIcon,
  LogOutIcon,
  HomeIcon,
  AlignCenterIcon,
  ClipboardIcon,
  UsersIcon,
  CopyIcon,
  MaximizeIcon,
  StarIcon,
  BellIcon,
  MessageSquareIcon,
  GridIcon,
  BriefcaseIcon,
  AlertCircleIcon,
},
  data() {
    var _this = this;
    return {
      showPassword: false,

      salutation: "Guten Tag",
      newestCases: [],
      casesWithDeadlines: [],
      casesDiagramLoaded: false,

caseOverviewOptions:      {
series: [{
  name:"Fälle",
    data: []
}],
colors: ["##3C87CD"],
chart: {
    id: 'area-datetime',
    
    locales: [{
      "name": "de",
      "options": {
        "months": ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
        "shortMonths": ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
        "days": ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
        "shortDays": ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
        "toolbar": {
            "exportToSVG": "Download SVG",
            "exportToPNG": "Download PNG",
            "menu": "Menü",
            "selection": "Auswahl",
            "selectionZoom": "Auswahl Zoom",
            "zoomIn": "Hineinzoomen",
            "zoomOut": "Rauszoomen",
            "pan": "Schwenken",
            "reset": "Zoom zurücksetzen"
        }
      }
    }],
    defaultLocale: 'de',
    type: 'area',
    height: '100%',
    zoom: {
        autoScaleYaxis: true
    },
    toolbar: {
        show: true
    },
},
// annotations: {
//     yaxis: [{
//         y: 50,
//         borderColor: "#24695c",
//         label: {
//             show: true,
//             text: 'Support',
//             style: {
//                 color: "#fff",
//                 background: "#24695c"
//             }
//         }
//     }],
//     xaxis: [{
//         x: new Date('15 Nov 2012').getTime(),
//         borderColor: "#24695c",
//         yAxisIndex: 50,          
//         label: {
//             show: false,
//             text: '$859,432',
//             style: {
//                 color: "#fff",
//                 background: "#24695c"
//             }
//         },
//     }]
// },
dataLabels: {
    enabled: false
},
markers: {
    size: 0,
    style: 'hollow',
},
xaxis: {
    type: 'datetime',
    min: new Date('10 01 2022').getTime(),
    tickAmount: 6,
    axisTicks: {
        show: false,
    },
    axisBorder: {
        show: false
    },
},
tooltip: {
    x: {
        format: 'dd MMM yyyy'
    },
},
fill: {
    type: 'gradient',
    gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100]
    }
},
// responsive: [{
//     breakpoint: 1366,
//     options: {
//         chart: {
//             height: 350
//         }
//     }
// },
//   {
//     breakpoint: 1238,
//     options: {
//         chart: {
//             height:300              
//         },
//         grid: {
//           padding: {              
//             bottom: 5,              
//           },  
//         }
//     }
// },
// {
//   breakpoint: 992,
//   options: {
//       chart: {
//           height: 300              
//       }
//   }
// },
// {
//     breakpoint: 551,
//     options: {          
//         grid: {
//           padding: {              
//             bottom:10,              
//           },  
//         }
//     }
// },
// {
//   breakpoint: 535,
//   options: {
//       chart: {
//           height: 250
//       }

//   }
// }],

// colors: [vihoAdminConfig.primary],
},

surveyDataLoaded: false,

surveysData: [
],

surveyData: [
  {
    data: [0,1,1]
  }
],

chartOptions: {
        chart: {
          type: "bar",
          height: 350,
        },
        tooltip:{
          enabled:false,
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: true,
        },
        xaxis: {
          categories: [
          ],
          // title: {
          //   text: "Stimmen"
          // },
          labels:{
            formatter: function (val){
              return val.toFixed(0);
            }
          }
        },
        yaxis: {
          categories: [
          ],
          // title: {
          //   text: "Antworten"
          // },
          labels:{
            formatter: function (val){
              return val;
            }
          }
        },
      },

userOverviewOptions: {
  options: {
    series: [100, 18, 54, 12],
    chart: {
      height: 150,
      width: 150,
      type: "radialBar",
      toolbar: {
          show: true,
      },
    },
    plotOptions: {
        radialBar: {
           offsetY: 0,
            startAngle: 0,
            endAngle: 270,
            hollow: {
              margin: 5,
              size: '30%',
              background: 'transparent',
              image: undefined,
            },
            colors: {
                backgroundBarColors: ["#e5edef"],
                backgroundBarOpacity: 1,
                backgroundBarRadius: 0
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                show: false,
              }
            }
        },
    },
    colors: ["##3C87CD", "#BA895D"],
    labels: ["Alle Fälle", "Neue Fälle", "Abgeschlossene Fälle", "Abgelehnte Fälle"],
    legend: {
      show: true,
      floating: true,
      fontSize: '16px',
      position: 'left',
      offsetX: 5,
      offsetY: 15,
      labels: {
        useSeriesColors: true,
      },
      formatter: function(seriesName, opts) {
        // return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex];
        if(_this.otherStats.length == 0) return seriesName;
        return seriesName + ":  " + _this.otherStats[opts.seriesIndex];
      },
      markers: {
        size: 0
      },
      itemMargin: {
        vertical: 3
      }
    },
  },
},

      credentials: {
        mail: "",
        password: ""
      },

      simpleStats: {
        newCasesThisWeek: 0,
        newCasesLastWeek: 0,
        finishedCasesThisWeek: 0,
        finishedCasesLastWeek: 0,
        newCasesLast24Hours: 0,
        newCasesThisYear: 0,
        newCasesLastYear: 0,
      },

      otherStats: []
    };
  },

  mounted() {
    const now = new Date();
    const h = now.getHours();
    this.salutation = h <= 10 ? "Guten Morgen" : h >= 17 ? "Guten Abend" : "Guten Tag";
    this.fetchNewestCases();
    this.fetchDeadlineCases();
    this.fetchCaseStats();
    this.fetchSimpleStats();
    this.fetchCasesDiagramData();
    this.fetchSurveyResults();
  },

  methods: {
    logError(error) {
      console.log(error);
    },

    fetchNewestCases(){
      var _this = this;
      this.$axios
        .get("/api/case", {
          params: {
            sort: "createdAt",
            order: "DESC",
            page: 0,
            size: 5
          }
        })
        .then(function (response) {
          _this.newestCases = response.data.content;
          _this.$forceUpdate();
        })
        .catch(this.onError);
    },

    fetchDeadlineCases(){
      var _this = this;
      this.$axios
        .get("/api/case/deadline-cases")
        .then(function (response) {
          _this.casesWithDeadlines = response.data.content;
          _this.$forceUpdate();
        })
        .catch(this.onError);
    },

    fetchSurveyResults(){
      var _this = this;
      this.$axios
        .get("/api/buergerinfo/survey/dashboard")
        .then(this.surveyResultFetched)
        .catch(err => {
          console.error(err);
        });
    },
    surveyResultFetched(response){
      var _this = this;
      this.surveys = response.data.content;
      for (let i = 0; i < this.surveys.length; i++) {
        const survey = this.surveys[i];
        // console.log(survey);
        this.$axios
        .get("/api/buergerinfo/survey/"+ survey.id+ "/votes")
        .then(function(response){
          // console.log(response);
          _this.singleSurveyResultFetched(response, survey)
        }



          // 
          )
        .catch(err => {
          console.error(err);
        });
        // TODO: Diagramme mit Ergebnissen analog Ergebnisdarstellung bei Umfragen-Details anzeigen
      }
    },
    singleSurveyResultFetched(response,survey){
      const data = response.data;
      let arr = [];
      const options = {
        chart: {
          type: "bar",
          height: 350,
        },
        tooltip:{
          enabled:false,
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: true,
        },
        xaxis: {
          categories: [
          ],
          labels:{
            formatter: function (val){
              return val.toFixed(0);
            }
          }
        },
        yaxis: {
          categories: [
          ],
          labels:{
            // rotate:-45,
            trim:true,
            hideOverlappingLabels: true,
            style: {
              fontSize:'10px',
              fontWeight:600,
            },
            formatter: function (val){
              return val;
            }
          
        },
      },};
      // console.log(response.data);
      // console.log(data);
      for(var prop in data){
        arr.push(data[prop]);
        options.xaxis.categories.push(prop);
      }
      const obj = {};
      obj.survey = survey;
      obj.data = [];
      obj.data.push(
        {
        data: arr
        });
      // this.surveyData[0].data = [3,1,5]
      obj.chartOptions = options;
      this.surveysData.push(obj);
      this.surveyDataLoaded = true;
      // console.log(this.surveysData)
      this.$forceUpdate();
    },
    fetchSimpleStats(){
      var _this = this;
      this.$axios
        .get("/api/case/statistics/simple")
        .then(function (response) {
          _this.simpleStats = response.data;
          _this.$forceUpdate();
        })
        .catch(this.onError);
    },

    fetchCasesDiagramData(){
      var _this = this;
      this.$axios
        .get("/api/case/statistics/casesdiagram")
        .then(function (response) {
          setTimeout(function(){
            _this.caseOverviewOptions.series = [
              {
                name:"Fälle",
                data: response.data
              }
            ];
            if(response.data.length > 0){
              _this.caseOverviewOptions.xaxis.min = response.data[0][0];
            }
            _this.casesDiagramLoaded = true;
            _this.$forceUpdate();
          }, 500);
        })
        .catch(this.onError);
    },

    fetchCaseStats(){
      var _this = this;
      this.$axios
        .get("/api/case/stats")
        .then(function (response) {
          setTimeout(function(){
            _this.otherStats = [response.data.ALL_COUNT, response.data.CREATED_COUNT, response.data.FINISHED_COUNT, response.data.REJECTED_COUNT];
            if(response.data.ALL_COUNT == 0){
              _this.userOverviewOptions.options.series = [0,0,0,0];
              return;
            }
            _this.userOverviewOptions.options.series = [
              100, (response.data.CREATED_COUNT / response.data.ALL_COUNT) * 100, (response.data.FINISHED_COUNT / response.data.ALL_COUNT) * 100, (response.data.REJECTED_COUNT / response.data.ALL_COUNT) * 100
            ];
            // console.log(_this.userOverviewOptions.options.series);
            _this.$forceUpdate();
          }, 500);
        })
        .catch(this.onError);
    },
  },
};
</script>

<style>
</style>