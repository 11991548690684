<template>
  <div :class="className">
    <v-select
      class="languagepicker"
      :menu-props="{minWidth: '180'}"
      v-model="lang"
      :items="possiblelanguages"
      append-icon=""
      item-text="name"
      item-value="shortForm"
      hide-details
    >
    <template slot="item" slot-scope="data">
      <img style="width:26px;height:17px;vertical-align: unset;" :src="'/files/flags/' + data.item.flag"> &nbsp; {{data.item.name}}
    </template>
    <template slot='selection' slot-scope='{item}'>
      <img style="width:26px;height:17px;vertical-align: unset;" :src="'/files/flags/' + item.flag">
    </template>
    </v-select>
  </div>
</template>

<script>
export default {

  props: {
    value: String,
    className: {
      type: String,
      default: function(){
        return "top-right";
      }
    },
  },

  data(){
    return {
      possiblelanguages: [],
    }
  },

  computed: {
    lang: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
        this.$emit('change', val);
      }
    },
  },

  watch: {
    value(val, prev){
      this.lang = this.value;
    },
  },

  mounted(){
    this.lang = this.value;
    this.getLanguages();
  },

  methods: {
    getLanguages() {
      var _this = this;
      this.$axios
        .get("/api/language/all")
        .then(function(response){
          _this.languages = response.data;
          _this.convertLanguages(_this.languages);
          
          // Make function accessible for getting flag by language
          Vue.prototype.$languages = _this.languages;
        })
        .catch(this.onError);
    },
    convertLanguages(languages){
      const lnlist = [];
      for (var i in languages){
        var language = {};
        var ln = languages[i].language;
        
        // console.log(this.$t(languages[i].language));
        language.name = this.$t(languages[i].language);
        language.flag = languages[i].flag;
        language.shortForm = languages[i].shortForm.toLowerCase();
        lnlist.push(language);
        
      }
      this.possiblelanguages = lnlist;
    },
  }
}
</script>
