<template>
    <div>
          <div class="container-fluid">
            <div class="page-header">
              <div class="row">
                <div class="col-sm-6">
                  <h3>{{$t('company management')}}</h3>
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="index.html">{{$t('management')}}</a></li>
                    <li class="breadcrumb-item active">{{$t('company management')}}</li>
                  </ol>
                </div>
                <div class="col-sm-6">
                  <!-- Bookmark Start-->
                  <div class="bookmark">
                    <ul>
                      <li><a href="javascript:void(0)" data-container="body" data-bs-toggle="popover" data-placement="top" title="" data-original-title="Tables"><i data-feather="inbox"></i></a></li>
                      <li><a href="javascript:void(0)" data-container="body" data-bs-toggle="popover" data-placement="top" title="" data-original-title="Chat"><i data-feather="message-square"></i></a></li>
                      <li><a href="javascript:void(0)" data-container="body" data-bs-toggle="popover" data-placement="top" title="" data-original-title="Icons"><i data-feather="command"></i></a></li>
                      <li><a href="javascript:void(0)" data-container="body" data-bs-toggle="popover" data-placement="top" title="" data-original-title="Learning"><i data-feather="layers"></i></a></li>
                      <li><a href="javascript:void(0)"><i class="bookmark-search" data-feather="star"></i></a>
                        <form class="form-inline search-form">
                          <div class="form-group form-control-search">
                            <input type="text" placeholder="Search..">
                          </div>
                        </form>
                      </li>
                    </ul>
                  </div>
                  <!-- Bookmark Ends-->
                </div>
              </div>
            </div>
          </div>
          <!-- Container-fluid starts-->
          <div class="container-fluid">
            <div class="row">

              <!-- Stammdaten -->

              <div class="company-data-container col-12 col-md-6 col-lg-7 row mt-0 mx-0">
                <div class="card col-12 col-lg-6">
                  <div class="card-header pb-0">
                    <h5 >{{$t('base_data')}}</h5>
                    <div class="text-subtitle-2">{{$t('base_data_hint')}}</div>
                  </div>
                  <div class="card-body">
                    <div class="form theme-form">
                      <div class="row">
                        <div class="col">
                          <div class="mb-3">
                            <label>{{$t('company_name')}}</label>
                            <input id="companyNameInput" class="form-control" type="text" v-model="baseData.designation">
                          </div>
                        </div>
                      </div>
                      <!-- v-if="$rights.includes('TENANT_INDEPENDENCE')" -->
                      <div class="row" v-if="$rights.includes('EDIT_OWN_TENANT')">
                        <div class="col py-0">
                          <div class="mb-3">
                            <label>{{$t('tenant')}}</label>
                            <v-autocomplete class="custom-autocomplete" :items="tenants" v-model="baseData.tenantId" hide-details="auto" dense :search-input.sync="tenantSearch" @keyup="fetchTenants(tenantSearch)" item-text='name' item-value='id'>
                              <template v-slot:append>
                                <v-icon>fas fa-chevron-down</v-icon>
                              </template>
                              <template v-slot:selection="{item}">
                                {{item.name}}
                              </template>
                              <template v-slot:item="{item}">
                                {{item.name}}
                              </template>
                            </v-autocomplete>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <label>{{$t('address')}}</label>
                        <div class="col-lg-10 py-0 pr-1">
                          <div class="mb-3">
                            <input class="form-control" type="text" v-model="baseData.street" placeholder="Straße">
                          </div>
                        </div>
                        <div class="col-lg-2 py-0 pl-1">
                          <div class="mb-3">
                            <input class="form-control" type="text" v-model="baseData.number" placeholder="Nr.">
                          </div>
                        </div>
                        <div class="col-lg-3 py-0 pr-1">
                          <div class="mb-3">
                            <input class="form-control" type="text" v-model="baseData.zip" placeholder="PLZ">
                          </div>
                        </div>
                        <div class="col-lg-9 py-0 pl-1">
                          <div class="mb-3">
                            <input class="form-control" type="text" v-model="baseData.city" placeholder="Ort">
                          </div>
                        </div>
                        <div class="col-lg-12 py-0">
                          <div class="mb-3">
                            <v-autocomplete class="custom-autocomplete" :items="countries" v-model="baseData.country" hide-details="auto" dense item-text='name' item-value='id'>
                              <template v-slot:append>
                                <v-icon>fas fa-chevron-down</v-icon>
                              </template>
                              <template v-slot:selection="{item}">
                                {{$t(item)}}
                              </template>
                              <template v-slot:item="{item}">
                                {{$t(item)}}
                              </template>
                            </v-autocomplete>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col py-0">
                          <div class="mb-3">
                            <label>{{$t('email')}}</label>
                            <input class="form-control" type="text" v-model="baseData.email" placeholder="E-Mail Adresse">
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <div class="mb-3">
                            <label>{{$t('phone_number')}}</label>
                            <input class="form-control" type="text" v-model="baseData.phone" :placeholder='$t("phone_number")'>
                          </div>
                        </div>
                      </div>
                      
                      <br>
                      <!-- <button style="float: right;" @click="saveCompany()" class="btn btn-primary" type="button" data-bs-original-title="" title="">Speichern</button> -->
                      <!-- <button class="btn btn-secondary mr-2" style="float: right;" @click="$routerBack()">Abbrechen</button> -->
                    </div>
                  </div>
                </div>

                <!-- Informationen -->
                <div class="card col-12 col-lg-6" v-bind:class="{'disabled': !$rights.includes('EDIT_COMPANIES_BASEDATA')}">
                  <div class="card-header pb-0">
                    <h5>{{$t('informations')}}</h5>
                    <div class="text-subtitle-2">{{$t('informations_hint')}}</div>
                  </div>
                  <div class="card-body">
                    <div class="row">
                        <div class="col py-0">
                          <div class="mb-3">
                            <label>{{$t('information')}} 1</label>
                            <input class="form-control" type="text" v-model="baseData.additionalInfo1" :placeholder='$t("add_information_hint")'>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col py-0">
                          <div class="mb-3">
                            <label>{{$t('information')}} 2</label>
                            <input class="form-control" type="text" v-model="baseData.additionalInfo2" :placeholder='$t("add_information_hint")'>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col py-0">
                          <div class="mb-3">
                            <label>{{$t('information')}} 3</label>
                            <input class="form-control" type="text" v-model="baseData.additionalInfo3" :placeholder='$t("add_information_hint")'>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col py-0">
                          <div class="mb-3">
                            <label>{{$t('information')}} 4</label>
                            <input class="form-control" type="text" v-model="baseData.additionalInfo4" :placeholder='$t("add_information_hint")'>
                          </div>
                        </div>
                      </div>

                      <br>
                      <button style="float: right;" @click="saveCompany()" class="btn btn-primary" type="button" data-bs-original-title="" title="">Speichern</button>
                  </div>
                </div>
              </div>



              <!-- Ansprechpartner -->
              <div id="contactPersonContainer" v-bind:class="{'disabled': !editMode || !$rights.includes('EDIT_COMPANIES_CONTACTPERSONS')}" class="col-12 col-md-6 col-lg-5">
                <div class="card">
                  <div class="card-header pb-0 d-flex justify-content-between justify-items-center">
                    <div>
                      <h5 >{{$t('contact_person')}}</h5>
                      <div class="text-subtitle-2">           </div>
                    </div>
                    <button :disabled="!editMode || !$rights.includes('EDIT_COMPANIES_CONTACTPERSONS')" v-show="!createContactPerson" style="float: right;" @click="startCreateContactPerson()" class="btn btn-primary" type="button" data-bs-original-title="" title="">Ansprechpartner erstellen</button>
                  </div>
                  <div class="card-body" v-if="!createContactPerson">
                    <div class="form theme-form">
                      <div class="table-responsive">
                        <table class="table table-bordernone">
                          <thead>
                            <tr>
                              <th>{{$t('name')}} & {{$t('email')}}</th>
                              <th>{{$t('phone_number')}}</th>
                              <th>&nbsp;</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="person in contactPersons">
                              <td class="text-left"><span style="font-weight: 600">{{ person.fullname }}</span><br>{{person.email}}</td>
                              <td class="text-left">
                                <span v-show="person.phone && person.phone != ''"><a :href="`tel:${formatPhoneNumberLink(person.phone)}`"><i class="fa fa-phone"></i> {{person.phone}}</a></span>
                                <span v-show="!person.phone || person.phone == ''">-</span>
                              </td>
                              <td>
                                <button class="btn btn-light mr-2" @click="editContactPerson(person)">
                                  <i class="fa fa-pencil"></i>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div class="card-body" v-else-if="createContactPerson">
                    <div class="form theme-form">
                      <div class="row">
                        <label>{{$t('name')}}</label>
                        <div class="col pr-1">
                          <div class="mb-3">
                            <input class="form-control" type="text" v-model="contactPerson.firstname" :placeholder='$t("firstname")'>
                          </div>
                        </div>
                        <div class="col pl-1">
                          <div class="mb-3">
                            <input class="form-control" type="text" v-model="contactPerson.lastname" :placeholder='$t("lastname")'>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col py-0">
                          <div class="mb-3">
                            <label>{{$t('position')}}</label>
                            <input class="form-control" type="text" v-model="contactPerson.position" :placeholder='$t("position")'>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col py-0">
                          <div class="mb-3">
                            <label>{{$t('email')}}</label>
                            <input class="form-control" type="text" v-model="contactPerson.email" :placeholder='$t("email")'>
                          </div>
                        </div>
                      </div>
                      
                      <div class="row">
                        <div class="col">
                          <div class="mb-3">
                            <label>{{$t('phone_number')}}</label>
                            <input class="form-control" type="text" v-model="contactPerson.phone" :placeholder='$t("phone_number")'>
                          </div>
                        </div>
                      </div>

                      <br>
                      <button style="float: right;" @click="saveContactPerson()" class="btn btn-primary" type="button" data-bs-original-title="" title="">{{contactPersonEditMode ? 'Ansprechpartner bearbeiten':"Ansprechpartner erstellen"}}</button>
                      <button class="btn btn-secondary mr-2" style="float: right;" @click="createContactPerson = false">Abbrechen</button>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Aktivitäten -->
              <div id="activityContainer" v-bind:class="{'disabled': !editMode || !$rights.includes('EDIT_COMPANIES_ACTIVITIES')}" class="col-12 col-md-12 col-lg-8">
                <div class="card">
                  <div class="card-header pb-0 d-flex justify-content-between justify-items-center">
                    <div>
                      <h5>{{$t('acitvities')}}</h5>
                      <div class="text-subtitle-2">{{$t('acitvities_hint')}}</div>
                    </div>
                    <div>
                      <button v-if="!createAcitvityForm && editMode && $rights.includes('EDIT_COMPANIES_ACTIVITIES')" style="float: right;" @click="openCreateAcitvityForm()" class="btn btn-primary" type="button" data-bs-original-title="" title="">Aktivität erstellen</button>
                    </div>
                  </div>
                  <div class="card-body" v-if="!createAcitvityForm">
                    <div class="form theme-form">
                      <div class="table-responsive">
                        <table class="table table-bordernone">
                          <thead>
                            <tr>
                              <th>{{$t('date')}}</th>
                              <th>{{$t('type')}}</th>
                              <th>{{$t('notes')}}</th>
                              <th>{{$t('result')}}</th>
                              <th>{{$t('reminder_at')}}</th>
                              <th>&nbsp;</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="entry in activities">
                              <td class="text-left">{{formateDate(entry.createdAt)}} {{formateTime(entry.createdAt)}}</td>
                              <td class="text-left">{{$t(entry.type)}}</td>
                              <td class="text-left">{{entry.description}}</td>
                              <td class="text-left">{{ getProtocolEntryResultText(entry) }}</td>
                              <td class="text-left">{{ entry.reminderFormatted }}</td>
                              <td>
                                <button :disabled="!editMode" @click="editProtocolEntry('ACTIVITY', entry)" class="btn btn-primary-light activity-edit-button" type="button" data-bs-original-title="" title=""><edit-icon class="custom-class"></edit-icon></button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="card-body" v-else>
                    <div class="form theme-form">
                      <div class="row">
                        <div class="col pr-1">
                          <label>{{$t('type')}}</label>
                          <div class="mb-3">
                            <v-select :disabled="!editMode" class="custom-autocomplete" :items="['CALL','EMAIL']" v-model="protocolEntry.type" hide-details="auto" dense  item-text='name' item-value='id'>
                              <template v-slot:append>
                                <v-icon>fas fa-chevron-down</v-icon>
                              </template>
                              <template v-slot:selection="{item}">
                                {{ $t(item) }}
                              </template>
                              <template v-slot:item="{item}">
                                {{ $t(item) }}
                              </template>
                            </v-select>
                          </div>
                        </div>
                        <div class="col pl-1">
                          <label>{{$t('reached')}}</label>
                          <div class="mb-3">
                            <v-select :disabled="!editMode" class="custom-autocomplete" clearable :items="['REACHED','NOT_REACHED', 'TRY_LATER']" v-model="protocolEntry.reached" hide-details="auto" dense item-text='name' item-value='id'>
                              <template v-slot:append>
                                <v-icon>fas fa-chevron-down</v-icon>
                              </template>
                              <template v-slot:selection="{item}">
                                {{ $t(item) }}
                              </template>
                              <template v-slot:item="{item}">
                                {{ $t(item) }}
                              </template>
                            </v-select>
                          </div>
                        </div>
                        <div class="col pl-1">
                          <label>{{$t('result')}}</label>
                          <div class="mb-3">
                            <v-select :disabled="!editMode" class="custom-autocomplete" clearable :items="['INTERESTED', 'BASICALLY_INTERESTED', 'NOT_INTERESTED']" v-model="protocolEntry.result" hide-details="auto" dense item-text='name' item-value='id'>
                              <template v-slot:append>
                                <v-icon>fas fa-chevron-down</v-icon>
                              </template>
                              <template v-slot:selection="{item}">
                                {{ $t(item) }}
                              </template>
                              <template v-slot:item="{item}">
                                {{ $t(item) }}
                              </template>
  
                            </v-select>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <div class="mb-3">
                            <label>{{$t('description')}} ({{ $t("optional") }})</label>
                            <textarea :disabled="!editMode" @keydown.enter.ctrl="saveProtocolEntry()" style="resize:none" class="form-control mb-3" type="text" v-model="protocolEntry.description" rows="5"></textarea>
                          </div>
                        </div>
                      </div>

                      <br>
                      <b>{{ protocolEntry.reminderDate == 'CUSTOM' ? $t("reminder_at") : $t("reminder_in") }} ({{ $t("optional") }})</b><br>
                      <div>
                        <v-select :disabled="!editMode" v-show="protocolEntry.reminderDate != 'CUSTOM'" class="custom-autocomplete" style="max-width: 30%" :items="reminderDates" v-model="protocolEntry.reminderDate" hide-details="auto" dense  item-text='name' item-value='id'>
                          <template v-slot:append>
                            <v-icon>fas fa-chevron-down</v-icon>
                          </template>
                          <template v-slot:selection="{item}">
                            {{ $t(item) }}
                          </template>
                          <template v-slot:item="{item}">
                            {{ $t(item) }}
                          </template>
                        </v-select>
                        <span v-show="protocolEntryDateShowOnlyText">{{ $formatDate(protocolEntry.reminder) }}</span>
                        <input
                          class="form-control digits"
                          style="max-width: 20%; display: inline;"
                          type="date"
                          v-show="protocolEntry.reminderDate == 'CUSTOM' && !protocolEntryDateShowOnlyText"
                          v-model="protocolEntry.reminder"
                          :disabled="!editMode"
                        />
                        <button :disabled="!editMode" v-show="protocolEntry.reminder && protocolEntry.reminderDate == 'CUSTOM'" @click="resetProtocolEntryReminder()" class="btn btn-primary-light activity-edit-button ml-2" type="button" data-bs-original-title="" title=""><trash-icon class="custom-class"></trash-icon></button>
                      </div>
                      <button :disabled="!editMode || !$rights.includes('EDIT_COMPANIES_ACTIVITIES')" style="float: right;" @click="saveProtocolEntry()" class="btn btn-primary" type="button" data-bs-original-title="" title="">{{$t("save_activity")}}</button>
                      <button :disabled="!editMode || !$rights.includes('EDIT_COMPANIES_ACTIVITIES')" class="btn btn-secondary mr-2" style="float: right;" @click="createAcitvityForm = false">Abbrechen</button>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Notizen und Ideen -->
              <div id="notesAndIdeasContainer"  v-bind:class="{'disabled': !editMode || !$rights.includes('EDIT_COMPANIES_NOTES')}" class="col-12 col-md-6 col-lg-4">
                <div class="card">
                  <div class="card-header pb-0 text-none">
                    <h5>{{$t('notes_ideas')}}</h5>
                    <div class="text-subtitle-2">{{$t('notes_ideas_hint')}}</div>
                  </div>
                  <div class="card-body">
                    <div class="form theme-form">
                      <div class="row">
                        <div class="col">
                          <div class="mb-3">
                            <textarea :disabled="!editMode || !$rights.includes('EDIT_COMPANIES_NOTES')" @keydown.enter.ctrl="saveProtocolEntry('NOTE')" style="resize:none" class="form-control mb-3" type="text" v-model="protocolNoteEntry.description" rows="5"></textarea>
                            <button :disabled="!editMode || !$rights.includes('EDIT_COMPANIES_NOTES')" style="float: right;" @click="saveProtocolEntry('NOTE')" class="btn btn-primary" type="button" data-bs-original-title="" title="">Notiz Speichern</button>
                          </div>
                        </div>
                      </div>
                      <div class="row" v-for="note in notes" :key="note.id">
                        <div class="col py-0">
                          <div class="mb-3">
                            <label v-if="note.createdAt != null && note.createdBy != null">{{formatiereDatumZeit(note.createdAt)}} - {{ note.createdBy.firstname }} {{ note.createdBy.lastname }}</label>
                            <p>{{ note.description }}</p><button :disabled="!editMode || !$rights.includes('EDIT_COMPANIES_NOTES')" @click="editProtocolEntry('NOTE', note)" class="btn btn-primary-light note-edit-button" type="button" data-bs-original-title="" title=""><edit-icon class="custom-class"></edit-icon></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Container-fluid Ends-->
  
  
      <!-- Delete Modal -->
      <div class="modal fade show" v-show="showDeleteConfirmation" tabindex="-1" aria-labelledby="exampleModalCenter" style="display: block; padding-right: 17px; background: rgba(0,0,0,.5);" aria-modal="true" role="dialog">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{$t('delete_user')}}</h5>
              <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close" data-bs-original-title="" title="" @click="(showDeleteConfirmation = false)"></button>
            </div>
            <div class="modal-body">
              <p>{{$t('confirm_delete_user')}}</p>
            </div>
            <div class="modal-footer">
              <button @click="(showDeleteConfirmation = false)" class="btn btn-secondary" type="button" data-bs-dismiss="modal" data-bs-original-title="" title="">{{$t('cancel')}}</button>
              <button @click="deleteUser()" class="btn btn-danger" type="button" data-bs-original-title="" title="">{{$t('delete')}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
    
  <script>
    import { MapPinIcon,PlusSquareIcon,EditIcon, TrashIcon } from 'vue-feather-icons'
  
    export default {
  
      props: {
        companyId: Number,
      },
      
      components: {
        MapPinIcon,
        PlusSquareIcon,
        EditIcon,
        TrashIcon
      },
      data() {
        return {
          editMode: false,
          showDeleteConfirmation: false,
          createContactPerson:false,
          createAcitvityForm:false,
          contactPersonEditMode: false,
          AcitvityEditMode:false,
          tenantSearch: "",
  
          categorySearchText: "",
  
          tenants: [],
          roles: [],
  
          categories: [],
          contactPersons:[],
          assignedCategories: [],
          activities: [],
          notes: [],
          information: null,

          reminderDates: [
            '4HOURS',
            '1DAY',
            '2DAYS',
            '3DAYS',
            '7DAYS',
            'NEXT_WEEK_TUESDAY',
            'NEXT_WEEK_THURSDAY',
            '2WEEKS',
            '3WEEKS',
            '4WEEKS',
            'CUSTOM'
          ],

          baseData:{
            designation: null,
            tenant: null,
            street: null,
            number:null,
            zip:null,
            city:null,
            email:null,
            phone: null,
            country: "GERMANY",
            additionalInfo1:null,
            additionalInfo2:null,
            additionalInfo3:null,
            additionalInfo4:null,
          },

          protocolEntry:{
            id: null,
            tenantId: null,
            companyId: null,
            description: null,
            result: null,
            reached: null,
            type: null,
            reminder: null,
            reminderDate: null,
          },
          protocolEntryDateShowOnlyText: false,

          protocolNoteEntry:{
            id: null,
            tenantId: null,
            companyId: null,
            description: null,
          },
  
          contactPerson: {
            email: "",
            firstname: "",
            lastname: "",
            position: "",
            phone:"",
            tenantId: null,
            companyId: null,
            role: null,
          },
        };
      },
  
      watch: {
        'user.tenantId': function(newValue, oldValue) {
          if(newValue == null){
            this.fetchRoles(this.$user.tenantId);
          }else{
            this.fetchRoles(newValue);
          }
          this.fetchCategories(newValue);
          this.assignedCategories = [];
        },
        'protocolEntry.reminderDate': function(newValue, oldValue) {
          if(newValue == null){
            this.protocolEntry.reminder = null;
          }else{
            let date = new Date();
            switch (newValue) {
              case '4HOURS':
                  date.setHours(date.getHours() + 4);
                  break;
              case '1DAY':
                  date.setDate(date.getDate() + 1);
                  break;
              case '2DAYS':
                  date.setDate(date.getDate() + 2);
                  break;
              case '3DAYS':
                  date.setDate(date.getDate() + 3);
                  break;
              case '7DAYS':
                  date.setDate(date.getDate() + 7);
                  break;
              case 'NEXT_WEEK_TUESDAY':
                  {
                      const currentDay = date.getDay();
                      const daysUntilNextTuesday = (9 - currentDay) % 7;
                      date.setDate(date.getDate() + daysUntilNextTuesday);
                  }
                  break;
              case 'NEXT_WEEK_THURSDAY':
                  {
                      const currentDay = date.getDay();
                      const daysUntilNextThursday = (11 - currentDay) % 7;
                      date.setDate(date.getDate() + daysUntilNextThursday);
                  }
                  break;
              case '2WEEKS':
                  date.setDate(date.getDate() + 14);
                  break;
              case '3WEEKS':
                  date.setDate(date.getDate() + 21);
                  break;
              case '4WEEKS':
                  date.setDate(date.getDate() + 28);
                  break;
              case 'CUSTOM':
                  break;
              default:
                break;
            }
            this.protocolEntry.reminder = date;
          }
        },
      },
    
      async mounted() {
        await this.$reloadUser();
        if (this.companyId != null && this.companyId > 0) {
          this.editMode = true;
          this.fetchEditingCompany();
          this.fetchProtocolEntries();
          this.fetchContactPersons();
        }else{


        }
        this.fetchCountries();
        this.fetchTenants();
        if(this.$user){
          this.fetchRoles(this.$user.tenantId);
        }
        // this.createContactPerson = !this.contactPersons.length >= 1;
        // this.createAcitvityForm = this.activities.length == 0;
        // console.log(this.createContactPerson);
        // console.log(this.$user);
      },
    
      methods: {
        onError(err) {
          if (
            err.response != null &&
            err.response.data != null
          ) {
            this.$noty.error(err.response.data);
          } else {
            console.error(err);
          }
        },
        fetchEditingCompany(){
          this.$axios
            .get("/api/company/" + this.companyId)
            .then(this.assignFetchedCompany)
            .catch(this.onError);
        },
        assignFetchedCompany(response){
          // console.log(response.data);
          const baseData = {};
          baseData.id = response.data.id;
          baseData.designation = response.data.designation;
          baseData.tenantId = response.data.tenant.id; 
          baseData.street = response.data.street; 
          baseData.number = response.data.number;
          baseData.zip = response.data.zip;
          baseData.city = response.data.city;
          baseData.email = response.data.email;
          baseData.phone = response.data.phone; 
          baseData.country = response.data.country;
          baseData.additionalInfo1 = response.data.additionalInfo1;
          baseData.additionalInfo2 = response.data.additionalInfo2;
          baseData.additionalInfo3 = response.data.additionalInfo3;
          baseData.additionalInfo4 = response.data.additionalInfo4;
          this.baseData = baseData;
        },
        fetchTenants(search = ""){
          if(search == null) return;
          var _this = this;
          this.$axios
            .get("/api/tenant?size=50&search=" + search)
            .then(function (response) {
              _this.tenants = response.data.content;
            })
            .catch(this.onError);
        },
        fetchCountries(){
          var _this = this;
          this.$axios
            .get("/api/language/countries")
            .then(function (response) {
              _this.countries = response.data;
            })
            .catch(this.onError);
        },
        fetchRoles(tenantId){
          if(tenantId == null) return;
          var _this = this;
          this.$axios
            .get("/api/role/tenant/" + tenantId)
            .then(function (response) {
              _this.roles = response.data.content;
            })
            .catch(this.onError);
        },
        fetchContactPersons(){
          var _this = this;
          this.$axios
            .get("/api/contactperson/company/"+ this.companyId)
            .then(function (response) {
              if(response.data.content == null || response.data.content.length == 0){
                _this.contactPersons = [];
                _this.createContactPerson = true;
              }else{
                _this.contactPersons = response.data.content;
                _this.createContactPerson = false;
              }
            })
            .catch(this.onError);
        },
        fetchProtocolEntries(){
          var _this = this;
          this.$axios
            .get("/api/protocolentry/company/" + this.companyId, {
              params: {
                sort: 'createdAt',
              },
            })
            .then(function (response) {
              // _this.activities = response.data.content;
              // _this.notes = response.data.content;
              
              const notes = [];
              const activities = [];
              if(response.data.content != null){
                for (let i = 0; i < response.data.content.length; i++) {
                  const element = response.data.content[i];
                  if(element.type == "NOTE"){
                    notes.push(element);
                  }else{
                    activities.push(element);
                  }
                }
              }
              _this.notes = notes;
              _this.activities = activities;

              if(_this.activities.length >= 1){
                _this.createAcitvityForm = false;
              }else if(_this.activities.length == 0){
                _this.createAcitvityForm = true;
              }

              // console.log(_this.activities.length);
            })
            .catch(this.onError);
        },
        openCreateAcitvityForm(){
          this.createAcitvityForm = true;
          this.resetProtocolEntry("ACTIVITY");
        },
        saveProtocolEntry(type = null){
          var _this = this;
          var dto = Object.assign({},type == "NOTE" ? this.protocolNoteEntry :this.protocolEntry);
          if(type != null){
            dto.type = type;
          }
          dto.companyId = this.companyId;
          if(type == "NOTE" && (dto.description == null || dto.description == "")){
            this.$noty.error(this.$t("description_empty"));
            return;
          }
          
          progressIndicator.hidden = false;
          this.$axios
            .post("/api/protocolentry", dto)
            .then(function(response) {
              if(type == "NOTE"){
                if(dto.id != null){
                  _this.$noty.success(_this.$t("note_edited"));
                }else{
                  _this.$noty.success(_this.$t("note_created"));
                }
              }else{
                if(dto.id != null){
                  _this.$noty.success(_this.$t("activity_edited"));
                }else{
                  _this.$noty.success(_this.$t("activity_created"));
                }
              }
              _this.fetchProtocolEntries();
              _this.createAcitvityForm = false;
              _this.resetProtocolEntry(type);
            })
            .catch(this.onError)
            .finally(this.$finalize);
        },
        resetProtocolEntry(type){
          if(type == "NOTE"){
              this.protocolNoteEntry = {
                id: null,
                tenantId: null,
                companyId: null,
                description: null,
              };
          }else{
            this.protocolEntry = {
              id: null,
              tenantId: null,
              companyId: null,
              type: null,
              description: null,
              result: null,
              reached: null,
              reminder: null,
              reminderDate: null,
            };
            this.protocolEntryDateShowOnlyText = false;
          }
        },
        saveCompany(){
          // console.log(this.baseData);
          const error = this.$t("please_fill_out_all_mandatory_fields");
          if(this.baseData.designation == "" || this.baseData.tenantId == null){
            this.$noty.error(error);
            return;
          }
          var dto = Object.assign({}, this.baseData);
          dto.active = true;
          if(!this.editMode){
            this._createCompany(dto);
          }else{
            this._updateCompany(dto);
          }
        },
        _createCompany(dto){
          var _this = this;
          progressIndicator.hidden = false;
          this.$axios
            .post("/api/company", dto)
            .then(function(response) {
              _this.$noty.success(_this.$t('company_created',{name: name}));
              _this.editMode = true;
              // console.log(response);
              _this.companyId = response.data.id;
              // console.log(_this.companyId);
              _this.$router.push("/edit-company?companyId=" + _this.companyId);
            })
            .catch(this.onError)
            .finally(this.$finalize);
        },
        _updateCompany(dto){
          var _this = this;
          progressIndicator.hidden = false;
          this.$axios
            .put("/api/company/" + this.companyId, dto)
            .then(function(response) {
              _this.$noty.success(_this.$t('company_updated',{name: name}));
              // _this.$router.push("/users");
            })
            .catch(this.onError)
            .finally(this.$finalize);
        },
        editContactPerson(person){
          this._resetContactPerson();
          this.createContactPerson = true;
          var dto = Object.assign({}, person);
          this.contactPerson = dto;
          this.contactPersonEditMode = true;
        },
        saveContactPerson(){
          const error = this.$t("please_fill_out_all_mandatory_fields");
          if(this.contactPerson.email == "" || this.contactPerson.firstname == "" || this.contactPerson.lastname == ""){
            this.$noty.error(error);
            return;
          }
          var dto = Object.assign({}, this.contactPerson);
          dto.roles = "";
          if(this.contactPerson.role != null){
            dto.roles += dto.role;
          }
          dto.companyId = this.companyId;

          dto.active = true;
          if(!this.contactPersonEditMode){
            this._createContactPerson(dto);
          }else{
            this._updateContactPerson(dto);
          }
          this._resetContactPerson();
        },
        startCreateContactPerson(){
          this.createContactPerson = true;
          this._resetContactPerson();
        },  
        _resetContactPerson(){
          this.contactPerson = {
            email: "",
            firstname: "",
            lastname: "",
            position: "",
            phone:"",
            tenantId: null,
            companyId: null,
            role: null,
          };
        },
        resetProtocolEntryReminder(){
          this.protocolEntry.reminder = null;
          this.protocolEntry.reminderDate = null;
          this.protocolEntryDateShowOnlyText = false;
        },
        _updateContactPerson(dto){
          var _this = this;
          progressIndicator.hidden = false;
          this.$axios
            .put("/api/contactperson/" + dto.id, dto)
            .then(function(response) {
              _this.$noty.success(_this.$t('contact_person_updated'));
              _this.fetchContactPersons();
              _this.createContactPerson = false;
            })
            .catch(this.onError)
            .finally(this.$finalize);
        },
        _createContactPerson(dto){
          var _this = this;
          progressIndicator.hidden = false;
          this.$axios
            .post("/api/contactperson", dto)
            .then(function(response) {
              _this.$noty.success(_this.$t('contact_person_created'));
              _this.fetchContactPersons();
              _this.createContactPerson = false;
              // _this.$router.push("/users");
            })
            .catch(this.onError)
            .finally(this.$finalize);
        },
        logError(error) {
          console.log(error);
        },
        formatiereDatumZeit(dateTimeString) {
          const options = { year: 'numeric', month: '2-digit', day: '2-digit',  };
          const dateTime = new Date(dateTimeString);

          const datumString = dateTime.toLocaleDateString('de-DE', options);
          // const uhrzeitString = dateTime.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' });

          return `${datumString} Uhr`;
        },
        formateDate(dateTimeString){
          const options = { year: 'numeric', month: '2-digit', day: '2-digit'};
          const date = new Date(dateTimeString);
          const dateString = date.toLocaleDateString('de-DE', options);
          return `${dateString}`;
        },
        formateTime(dateTimeString){
          const options = {hour: '2-digit', minute: '2-digit'};
          const time = new Date(dateTimeString);
          const timeString = time.toLocaleTimeString('de-DE', options);
          return `${timeString} Uhr`;
        },
        formatPhoneNumberLink(phoneNumber){
          var no = phoneNumber.trim();
          if(no.startsWith("00")) no = "+" + no.substring(2);
          if(no.startsWith("0")) no = "+49" + no.substring(1);
          return no;
        },
        editProtocolEntry(type, note){
          if(type == "NOTE"){
            this.protocolNoteEntry.id = note.id;
            this.protocolNoteEntry.description = note.description;
            return;
          }
          this.createAcitvityForm = true;
          this.protocolEntry.id = note.id;
          this.protocolEntry.description = note.description;
          this.protocolEntry.reached = note.reached;
          this.protocolEntry.result = note.result;
          this.protocolEntry.type = note.type;
          if(note.reminder){
            if(note.reminder.includes("T")) note.reminder = note.reminder.substring(0, note.reminder.indexOf("T"));
            this.protocolEntry.reminder = note.reminder;
            this.protocolEntryDateShowOnlyText = true;
            this.protocolEntry.reminderDate = "CUSTOM";
          }else{
            this.protocolEntry.reminder = null;
            this.protocolEntryDateShowOnlyText = false;
          }
          this.$forceUpdate();
        },

        getProtocolEntryResultText(entry){
          if(entry.reached != null && entry.result != null){
            return this.$t(entry.reached) + " - " + this.$t(entry.result);
          }
          if(entry.result != null){
            return this.$t(entry.result);
          }
          if(entry.reached != null){
            return this.$t(entry.reached);
          }
          return "-";
        }
      },
    };
    </script>
    
    <style scoped>
      
    </style>