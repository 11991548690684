<template>
  <div>
    <div class="side-background" style="background-image: url('/static/images/bg.webp')">
      <div class="side-background-foreground">
        <div class="container-fluid p-0">
        <div class="row">
          <div class="col-12 p-0">
            <div class="login-card">
              <div class="theme-form login-form">
                <div class="logo-wrapper d-flex justify-content-center"><img class="img-fluid" src="/static/images/logo/logo.svg" alt=""></div>
                <div class="form-group">
                  <label>{{$t('email')}}</label>
                  <input class="form-control" type="email" required="" v-model="credentials.mail" @keypress.enter="login()" placeholder="Test@gmail.com">
                </div>
                <div class="form-group">
                  <label>{{$t('password')}}</label>
                  <input class="form-control" type="password" v-model="credentials.password" @keypress.enter="login()" required="" placeholder="*********">
                  <a class="link-light" @click="$router.push('/forgot-password')">{{$t('forgot_password')}}</a>
                </div>
                <div class="form-group">
                  <button class="btn btn-primary-light btn-block" @click="login()"><a href="javascript:void(0)">{{$t('login')}}</a></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  props: {
    routingTarget: String,
  },


  data() {
    return {
      showPassword: false,

      credentials: {
        mail: "",
        password: ""
      },
    };
  },

  mounted() {
    this.$axios.checkBearer().then(this.onLoggedIn).catch(this.discardError);
    this.checkIfFromMailVerification();
  },

  methods: {
    login() {
      this.$axios.credentials = this.credentials;
      this.$axios.authorize().then(this.onLoggedIn).catch(this.onLoginFailed);
    },
    onLoggedIn(response) {
      this.$axios
        .get("/api/user/me")
        .then(this.onSuccessfulLogin)
        .catch(this.onLoginFailed);
    },
    onSuccessfulLogin(response){
      this.user = response.data;
      Vue.prototype.$user = response.data;
      Vue.prototype.$rights = response.data.allRights;

      console.log(response);
      console.log("Success");
      
      if(this.routingTarget != null){
        this.$router.push(this.routingTarget);
        window.location.reload();
        return;
      }

      if(this.$rights.includes("VIEW_COMPANIES")){
        this.$router.push("companies");
      }if(this.$rights.includes("CREATE_USER")){
        this.$router.push("users");
      }else if(this.$rights.includes("MANAGE_ROLES")){
        this.$router.push("roles");
      }else if(this.$rights.includes("TENANT_INDEPENDENCE") || this.$rights.includes("EDIT_OWN_TENANT")){
        this.$router.push("tenants");
      }
      
      this.$forceUpdate();
    },
    onLoginFailed(error) {
      console.error(error);
      this.$noty.error(this.$t("ERROR_INVALID_CREDENTIALS"));
      showLoadingCircle(false);
    },
    checkIfFromMailVerification(){
      var url = new URL(window.location.href);
      var token = url.searchParams.get("verified");
      if(!token) return;
      this.$noty.success(this.$t("email_verified"));
    },
    logError(error) {
      console.log(error);
    },
    discardError(error) {}
  },
};
</script>

<style>
</style>