<template>
  <div>
      <div class="container-fluid">
        <div class="page-header">
          <div class="row">
            <div class="col-sm-6">
              <h3>{{$t('tenant management')}}</h3>
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a>{{$t('management')}}</a></li>
                <li class="breadcrumb-item active">{{$t('tenant management')}}</li>
              </ol>
            </div>
            <div class="col-sm-6">
              <!-- Bookmark Start-->
              <div class="bookmark">
                <ul>
                  <li><a href="javascript:void(0)" data-container="body" data-bs-toggle="popover" data-placement="top" title="" data-original-title="Tables"><i data-feather="inbox"></i></a></li>
                  <li><a href="javascript:void(0)" data-container="body" data-bs-toggle="popover" data-placement="top" title="" data-original-title="Chat"><i data-feather="message-square"></i></a></li>
                  <li><a href="javascript:void(0)" data-container="body" data-bs-toggle="popover" data-placement="top" title="" data-original-title="Icons"><i data-feather="command"></i></a></li>
                  <li><a href="javascript:void(0)" data-container="body" data-bs-toggle="popover" data-placement="top" title="" data-original-title="Learning"><i data-feather="layers"></i></a></li>
                  <li><a href="javascript:void(0)"><i class="bookmark-search" data-feather="star"></i></a>
                    <form class="form-inline search-form">
                      <div class="form-group form-control-search">
                        <input type="text" placeholder="Search..">
                      </div>
                    </form>
                  </li>
                </ul>
              </div>
              <!-- Bookmark Ends-->
            </div>
          </div>
        </div>
      </div>
      <!-- Container-fluid starts-->
      <div class="container-fluid">
        <div class="mt-10 col-md-12 p-0 project-list">
          <div class="card">
            <div class="row">
              <div class="col-md-4 p-0">
                <div class="mb-2">
                  <input class="form-control" type="text" v-model="search" @change="fetchTenants(true)" placeholder="Suche">
                </div>
              </div>
              <div class="col-md-8 p-0">                    
                <div class="form-group mb-0 me-0"></div>
                <a class="btn btn-primary-light" v-bind:class="{'disabled': !newsletterTenantsAdded}" @click="openNewsletterPopup()"> <mail-icon class="custom-class"></mail-icon>{{ $t("newsletter_mail") }}</a>
                <a class="btn btn-primary-light mr-2" @click="$router.push('/create-tenant')"> <plus-square-icon class="custom-class"></plus-square-icon>{{ $t('create_new_tenant') }}</a>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
                <div class="card">
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">
                            <input type="checkbox" @click="selectAllTenants()">
                          </th>
                          <th scope="col">{{$t('name')}}</th>
                          <th scope="col">{{$t('zip')}}</th>
                          <th scope="col" v-show="$user.superAdmin">{{$t('country')}}</th>
                          <th scope="col">{{$t('actions')}}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="tenant in tenants" :key="tenant.id">
                          <td><input type="checkbox" :checked="newsletter.tenantIds.includes(tenant.id)" @click="selectTenant(tenant.id)"></td>
                          <td><a class="link" @click="$router.push('/edit-tenant?tenantId=' + tenant.id)">{{tenant.name}}</a></td>
                          <td>{{tenant.zip}}</td>
                          <td v-show="$user.superAdmin">{{tenant.country}}</td>
                          <td>
                            <button :id="tenant.name + 'EditButton'" class="btn btn-light" @click="$router.push('/edit-tenant?tenantId=' + tenant.id)"> <edit-icon size="16"></edit-icon></button>
                            <button :id="tenant.name + 'DeleteButton'" class="btn btn-danger ml-2" @click="showDeleteConfirmationDialog(tenant)"> <trash-icon size="16"></trash-icon></button>

                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
          </div>
        </div>
      </div>
      <div style="position:absolute; bottom: 100px;left:50%;height:40px;width:40px " v-intersect.quiet="infiniteScrolling"></div>
      <!-- Container-fluid Ends-->

      <!-- Newsletter Modal -->
      <div class="modal fade show" v-show="showNewsletterPopup" tabindex="-1" aria-labelledby="exampleModalCenter" style="display: block; padding-right: 17px; background: rgba(0,0,0,.5);" aria-modal="true" role="dialog">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{$t('newsletter_mail')}} ({{ newsletter.sendToAllTenants ? $t("all") : newsletter.tenantIds.length }})</h5>
              <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close" data-bs-original-title="" title="" @click="(showNewsletterPopup = false)"></button>
            </div>
            <div class="modal-body">
              <!-- Subject Input -->
              <div class="mb-3">
                <label>{{$t('subject')}}</label>
                <input class="form-control" type="text" v-model="newsletter.subject">
              </div>
              <!-- HTML Formatter -->
              <label>{{$t('content')}}</label>
              <vue-editor
                v-model="newsletter.text"
                useCustomImageHandler
                :editorToolbar="customToolbar"
                @image-added="handleImageAddedEditor"
                @image-removed="handleImageDeletedEditor"
              />
            </div>
            <div class="modal-footer">
              <button @click="(showNewsletterPopup = false)" class="btn btn-secondary" type="button" data-bs-dismiss="modal" data-bs-original-title="" title="">{{$t('cancel')}}</button>
              <button @click="sendNewsletterMail()" class="btn btn-success" type="button" data-bs-original-title="" title="">{{$t('send_newsletter')}}</button>
            </div>
          </div>
        </div>
      </div>

      <div v-if="tenantToDelete != null">
        <ConfirmDialog v-model="showDeleteConfirmation" :headline="$t('confirm')" :text="$t('confirm_delete_tenant', {name: tenantToDelete.name})" @actionClicked="deleteTenant(tenantToDelete)" />
      </div>
    
  </div>
</template>

<script>

import pagination from './custom/pagination.vue'
import { MapPinIcon,PlusSquareIcon,ClipboardIcon,FileTextIcon,TrashIcon, EditIcon, MailIcon } from 'vue-feather-icons'

export default {

  components: {
    MapPinIcon,
    PlusSquareIcon,
    ClipboardIcon,
    FileTextIcon,
    TrashIcon,
    EditIcon,
    MailIcon
  },

  data() {
    return {
      page: 1,
      totalPages: 1,
      ElementPerPage: 50,
      sort: null,
      order: true,
      showNewsletterPopup: false,
      routes: {
        tenants: "/api/tenant",
        filter: "/api/tenants/search/"
      },
      search: "",
      convertedTenants: [],
      tenantFilter: null,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      ],
      rules: [(value) => !!value || this.$t("required")],
      tenants: [],
      roles: [],
      createTenantPopup: false,
      menu: false,
      createmode: true,

      newsletter: {
        tenantIds: [],
        sendToAllTenants: false,
        text: "",
        subject: "",
      },

      newsletterTenantsAdded: false,

      showDeleteConfirmation: false,
      tenantToDelete: null,

      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        // [
        //   { align: "" },
        //   { align: "center" },
        //   { align: "right" },
        //   { align: "justify" },
        // ],
        // ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        // [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["link","image"],
        // ["clean"],
      ],

      // Personal Data
      me: null,
    };
  },

  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },

  mounted() {
    this.fetchTenants();
  },

  methods: {
    infiniteScrolling(entries, observer, isIntersecting) {
        if(isIntersecting){
          setTimeout(() => {
            var _this = this;
            if(this.page  < this.totalPages){
                    this.page++;
                    this.$axios
                        .get(this.routes.tenants, {
                          params: {
                            sort: this.sort,
                            order: this.order ? "ASC":"DESC",
                            page: this.page -1,
                            size: this.ElementPerPage
                          }
                        })
                        .then(function (response) {
                          if(response.data.content.length > 1) {
                                response.data.content.forEach(item => _this.tenants.push(item));
                                _this.totalPages = response.data.totalPages;
                            }
                        })
                        .catch(this.onError);
                }
            }, 500);
        }
    },
    previousPage() {
      this.page = this.page - 1;
      this.fetchTenants();
    },
    nextPage() {
      this.page = this.page + 1;
      this.fetchTenants();
    },
    onError(err) {
      if (
        err.response != null &&
        err.response.data != null &&
        err.response.data.message != null
      ) {
        this.$noty.error(err.response.data.message);
      } else {
        console.error(err);
      }
    },
    openNewsletterPopup(){
      this.showNewsletterPopup = true;
    },
    closeNewsletterPopup() {
      this.showNewsletterPopup = false;
    },
    selectAllTenants(){
      if(this.newsletter.sendToAllTenants){
        // Unselect everything
        this.newsletter.sendToAllTenants = false;
        this.newsletterTenantsAdded = false;
        this.newsletter.tenantIds = [];
      }else{
        // Select everything
        this.newsletter.sendToAllTenants = true;
        this.newsletterTenantsAdded = true;
        this.newsletter.tenantIds = this.newsletter.tenantIds.concat(this.tenants.map(a => a.id));
      }
      // console.log(this.newsletter);
      this.$forceUpdate();
    },
    selectTenant(tenantId){
      if(this.newsletter.tenantIds.includes(tenantId)){
        this.newsletter.sendToAllTenants = false;
        this.newsletter.tenantIds.splice(this.newsletter.tenantIds.indexOf(tenantId));
        if(this.newsletter.tenantIds.length == 0) this.newsletterTenantsAdded = false;
      }else{
        this.newsletter.tenantIds.push(tenantId);
        this.newsletterTenantsAdded = true;
      }
      this.$forceUpdate();
    },
    fetchTenants(resetPage = false) {
      if(resetPage) this.page = 1;
      var _this = this;
      this.$axios
        .get(this.routes.tenants, {
          params: {
            search: _this.search,
            sort: _this.sort,
            order: _this.order ? "ASC":"DESC",
            page: _this.page -1,
            size: _this.ElementPerPage
          }
        })
        .then(function (response) {
          _this.tenants = response.data.content;
          _this.totalPages = response.data.totalPages;
          if(_this.newsletter.sendToAllTenants) _this.newsletter.tenantIds.push(_this.tenants.map(a => a.id));
          _this.$forceUpdate();
        })
        .catch(this.onError);
    },
    updatePage() {
      this.fetchTenants();
    },
    updateVisibleTenants() {
      var _this = this;
      this.tenants = this.tenants.slice(
        (_this.page = _this.ElementPerPage),
        (_this.page = _this.ElementPerPage) + _this.ElementPerPage
      );

      if (this.tenants.length == 0 && this.page > 0) {
        this.updatePage(this.page - 1);
      }
    },

    tableRowClicked(tenantId){
      if(this.$rights.includes('EDIT_CUSTOMERS') || this.$rights.includes('TENANT_INDEPENDENCE')){
        this.$router.push('/edit-tenant?tenantId=' + tenantId)
      }
    },

    createTenant() {
      var _this = this;

      var saveTenant = {};
      Object.assign(saveTenant, this.editedTenant);

      saveTenant.tenantId = saveTenant.customer != null ? saveTenant.customer.id : -1;
      saveTenant.tenantname = saveTenant.mail;

      this.$axios
        .post(this.routes.tenants, saveTenant)
        .then(function (response) {
          _this.$noty.success(_this.$t("tenant_created"));
          var tenant = response.data;
          if (_this.editedTenant.role != null && _this.editedTenant.role.id != null) {
            _this.addRoleToTenant(tenant.id, _this.editedTenant.role.id);
          }
          _this.addRightsToTenant(tenant.id, _this.editedTenant.rights);
          _this.resetEditedTenant();
          _this.createTenantPopup = false;
          _this.page = 1;
          _this.fetchTenants();
        })
        .catch(this.onError);
    },
    sendNewsletterMail(){
      var _this = this;

      var newsletterMail = Object.assign({}, this.newsletter);
      this.$axios
        .post("/api/tenant/mail", newsletterMail)
        .then(function (response) {
          _this.$noty.success(_this.$t("newsletter_mail_sent"));
          _this.newsletter = {
            tenantIds: [],
            sendToAllTenants: false,
            text: "",
            subject: "",
          };
          _this.showNewsletterPopup = false;
        })
        .catch(this.onError);
    },
    editTenant() {
      var _this = this;

      var saveTenant = {};
      Object.assign(saveTenant, this.editedTenant);

      saveTenant.tenantId = saveTenant.customer != null ? saveTenant.customer.id : -1;
      saveTenant.tenantname = saveTenant.mail;

      this.$axios
        .put(this.routes.tenants, saveTenant)
        .then(function (response) {
          var tenant = response.data;
          _this.$noty.success(_this.$t("tenant_updated"));
          if (_this.editedTenant.role != null && _this.editedTenant.role != null) {
            _this.addRoleToTenant(tenant.id, _this.editedTenant.role.id);
          }
          _this.addRightsToTenant(tenant.id, _this.editedTenant.rights);
          _this.resetEditedTenant();
          _this.createTenantPopup = false;
          _this.fetchTenants();
        })
        .catch(this.onError);
    },
    handleImageAddedEditor(file, Editor, cursorLocation, resetUploader) {
      var formData = new FormData();

      formData.append("file", file);

      this.$axios
        .post("/files/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          var link = "/files/images/" + response.data;

          Editor.insertEmbed(cursorLocation, "image", link);

          resetUploader();
        });
    },
    handleImageDeletedEditor(image) {
      if (image.startsWith("/files/images/")) image = image.substring(14);

      this.$axios
        .delete("/files/upload/" + image)
        .then(function (response) {})
        .catch(function (err) {
          console.error(err);
        });
    },
    showDeleteConfirmationDialog(tenant){
      this.tenantToDelete = Object.assign({}, tenant);
      this.showDeleteConfirmation = true;
    },
    deleteTenant(tenant){
      var _this = this;
      const tenantName = tenant.name;
      const tenantId = tenant.id;
      this.$axios
        .delete("/api/tenant/" + tenantId)
        .then(function (response) {
          _this.$noty.success(_this.$t("tenant_deleted", {name: tenantName}))
          _this.fetchTenants(true);
        })
        .catch(function (err) {
          console.error(err);
        });
    },
    getJsonOptions() {
      const options = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      return options;
    },
  },
};
</script>

<style scoped>
.v-dialog > .v-card > .v-card__actions {
  padding: 8px 16px;
}
</style>

