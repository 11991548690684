<template>
  <div>
    <div class="side-background" style="background-image: url('/static/images/bg.webp')">
      <div class="side-background-foreground">
        <div class="container-fluid p-0">
        <div class="row">
          <div class="col-12 p-0">
            <div class="login-card">
              <div class="theme-form login-form">
                <div class="logo-wrapper d-flex justify-content-center"><img class="img-fluid" src="/static/images/logo/logo.svg" alt=""></div>
                <div class="form-group">
                  <label>{{$t('email')}}</label>
                  <input class="form-control" type="email" required="" v-model="email" @keypress.enter="resetPassword()" placeholder="Test@gmail.com">
                  <a class="link-light" @click="$router.push('/login')">{{$t('back_to_login')}}</a>
                </div>
                <div class="form-group">
                  <button class="btn btn-primary-light btn-block" @click="resetPassword()"><a href="javascript:void(0)">{{$t('reset_password')}}</a></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: null,
    };
  },

  mounted() {
    
  },

  methods: {
    resetPassword() {
      var _this = this;
      
      const email = this.email;

      progressIndicator.hidden = false;
      showLoadingCircle(true);

      this.$axios.post("/api/user/forgot-password", {email: email}).then(function(response){ 
          _this.$router.push("/login");
          _this.$noty.success(_this.$t("password_reset_mail_sent"))
      }).catch(function(error){
          if(error.response != null && error.response.data != null){
            _this.$noty.error(error.response.data);
          }else{
            console.error(error);
          }
      }).finally(() => {
        progressIndicator.hidden = true;
        showLoadingCircle(false);
      });
    }
  },
};
</script>

<style>
</style>