<template>
  <div class="row justify-content-center">
    <div
      class="px-0 base-image-input"
      v-bind:class="{ 'rounded-circle': shape == 'circle' }"
      :style="{ 'background-image': `url(${imageData})` }"
      @click="chooseImage"
    >
      <img
        v-if="!imageData"
        src="https://picsum.photos/300"
        class="px-0 placeholder"
        v-bind:class="{ 'rounded-circle': shape == 'circle' }"
      />
      <input
        class="file-input"
        ref="fileInput"
        type="file"
        @input="onSelectFile"
        :accept="accept"
      />
    </div>
    <div v-if="showhint">
      <div class="text-center text-h6">{{ hint }}</div>
      <div class="text-center text-subtitle-2 grey--text text--lighten-5">
        {{ $t("upload_file_hint") }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageUpload",

  props: {
    value: {
      type: Object,
      default: "",
    },
    showhint: {
      type: Boolean,
      default: true,
    },
    hint: {
      type: String,
      default: "",
    },
    shape: {
      type: String,
      default: "square",
    },
  },

  data() {
    return {
      imageData: null,
      accept: "image/png, image/jpeg, image/jpg",
      suffix: "image",
      suffix_plural: "images",
      multiple: false,
    };
  },

  watch: {
    value: {
      handler(val) {
        if(this.imageData == null && typeof val == "string"){
          this.imageData = "/files/images/" + val;
        }
      },
      deep: false
    },
  },

  computed: {
    fileNames: {
      get() {
        if (typeof this.value == "string") {
          return [this.value];
        }
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },

  mounted() {
    if (this.allowOtherFileTypes) {
      this.accept = "/*";
      this.suffix = "file";
      this.suffix_plural = "files";
    }
    this.fileNames = [];
    console.log(this.value);
  },

  methods: {
    handleUpload(files) {
      var _this = this;
      if (!Array.isArray(files)) {
        files = [files];
      }

      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        var formData = new FormData();
        formData.append("file", file);
        formData.append("internalFile", this.internalFiles);

        this.$axios
          .post("/files/upload", formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then(function (response) {
            var uploadedFile = response.data;
            if (_this.vModelPrefix != null)
              uploadedFile = _this.vModelPrefix + uploadedFile;

            _this.newFilesSelected = true;
            if (_this.multiple) {
              _this.fileNames.push(uploadedFile);
            } else {
              if (_this.fileNames != null && _this.fileNames.length > 0) {
                // Remove single file from Server (if not multiple selected!)
                _this.removeFile(_this.fileNames[0]);
              }
              _this.fileNames = uploadedFile;
            }
            _this.files = [];
          })
          .catch(this.onError);
      }
    },
    chooseImage() {
      // this.$refs.fileInput.$refs.input.click();
      this.$refs.fileInput.click();
    },
    onSelectFile() {
      var _this = this;
      const input = this.$refs.fileInput;
      const files = input.files;

      const img = new Image();

      img.onload = function () {
        const width = img.width;
        const height = img.height;

        console.log(width +' ' + height)

        if (width !== height) {
          _this.$noty.error(_this.$t('image_square_hint'));
        } else {
          _this.handleUpload(files[0]);
          if (files && files[0]) {
            const reader = new FileReader();
            reader.onload = (e) => {
              _this.imageData = e.target.result;
            };
            reader.readAsDataURL(files[0]);
            _this.$emit("input", files[0]);
          }
        }
      };

      img.onerror = function () {
        callback(false); // Falls das Bild nicht geladen werden kann, wird es als nicht quadratisch betrachtet
      };

      // Lade das Bild, um seine Abmessungen zu ermitteln
      const reader = new FileReader();
      reader.onloadend = function () {
        img.src = reader.result;
      };
      reader.readAsDataURL(files[0]);
    },
    onError(err) {
      if (err.response != null && err.response.data != null) {
        this.$noty.error(err.response.data);
      } else {
        console.error(err);
      }
    },
  },
};
</script>

<style scoped>
.base-image-input {
  display: block;
  width: 200px;
  height: 200px;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}
.placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}
.placeholder:hover {
  background: #e0e0e0;
}
.file-input {
  display: none;
}
</style>