<template>
  <!-- <v-btn
    class="mx-1 btn btn-primary"
    v-bind="{ ...$attrs, loading: loadingProp }"
    @click="loadingProp = !loadingProp"
    color="white"
  >
    <template v-slot:prepend-item>
      <div>
        <v-icon>fas fa trash</v-icon>
      </div>
    </template>
    <template v-slot:loader>
      <v-progress-circular :size="23" color="white" :width="2" indeterminate>
      </v-progress-circular>{{ title }}
    </template>
    {{ title }}
  </v-btn> -->

  <button
    :disabled="disabled"
    v-bind:class="{'pl-1' :loadingProp}"
    class="mx-1 btn btn-primary"
    color="white"
    type="button"
    @click="execute()"
  >
    <v-progress-circular v-show="loadingProp" class="mx-3" :size="20" :width="2" color="white" indeterminate>
    </v-progress-circular>{{ title }}
  </button>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    do: {
      type: Function,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    loading(isLoading) {
      if (isLoading) {
        this.loadingProp = true;
        return;
      }
      else{
        this.loadingProp = false;
      }
    },
  },
  data() {
    return {
      loadingProp: false,
    };
  },

  mounted() {},

  methods: {
    execute(){
        try {
            this.do()
        } catch (error) {
            console.log(error)
        }
    }
  },
};
</script>
