<template>
  <div>
      <div class="container-fluid">
        <div class="page-header">
          <div class="row">
            <div class="col-sm-6">
              <h3>{{$t('role management')}}</h3>
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a>{{$t('management')}}</a></li>
                <li class="breadcrumb-item active">{{$t('role management')}}</li>
              </ol>
            </div>
            <div class="col-sm-6">
              <!-- Bookmark Start-->
              <div class="bookmark">
                <ul>
                  <li><a href="javascript:void(0)" data-container="body" data-bs-toggle="popover" data-placement="top" title="" data-original-title="Tables"><i data-feather="inbox"></i></a></li>
                  <li><a href="javascript:void(0)" data-container="body" data-bs-toggle="popover" data-placement="top" title="" data-original-title="Chat"><i data-feather="message-square"></i></a></li>
                  <li><a href="javascript:void(0)" data-container="body" data-bs-toggle="popover" data-placement="top" title="" data-original-title="Icons"><i data-feather="command"></i></a></li>
                  <li><a href="javascript:void(0)" data-container="body" data-bs-toggle="popover" data-placement="top" title="" data-original-title="Learning"><i data-feather="layers"></i></a></li>
                  <li><a href="javascript:void(0)"><i class="bookmark-search" data-feather="star"></i></a>
                    <form class="form-inline search-form">
                      <div class="form-group form-control-search">
                        <input type="text" placeholder="Search..">
                      </div>
                    </form>
                  </li>
                </ul>
              </div>
              <!-- Bookmark Ends-->
            </div>
          </div>
        </div>
      </div>
      <!-- Container-fluid starts-->
      <div class="container-fluid">
        <div class="mt-10 col-md-12 p-0 project-list">
          <div class="card">
            <div class="row">
              <div class="col-md-6 p-0">
                <div class="mb-3" v-if="$rights.includes('TENANT_INDEPENDENCE')">
                  <v-autocomplete class="custom-autocomplete" :items="tenants" v-model="tenantContextId" hide-details="auto" dense :search-input.sync="tenantSearch" @keyup="fetchTenants(tenantSearch)" item-text='name' item-value='id'>
                    <template v-slot:append>
                      <v-icon>fas fa-chevron-down</v-icon>
                    </template>
                    <template v-slot:selection="{item}">
                      {{item.name}}
                    </template>
                    <template v-slot:item="{item}">
                      {{item.name}}
                    </template>

                  </v-autocomplete>
                </div>
              </div>
              <div class="col-md-6 p-0">                    
                <div class="form-group mb-0 me-0"></div><a class="btn btn-primary-light" @click="openEditModal()"> <plus-square-icon class="custom-class"></plus-square-icon>{{$t('create_role')}}</a>
              </div>
            </div>
          </div>
        </div>
        <div class="row" style="z-index: 1;position: relative;">
          <div class="col-sm-12">
                <div class="card">
                  <div>
                    <table class="table stickyheader-table center-except-first-column">
                      <thead>
                        <tr>
                          <th>{{$t('right_role')}}</th>
                          <th v-for="role in roles" :key="role.id" scope="col">{{ role.name }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="right in rights" :key="right">
                          <td>
                            {{ $t(right) }}
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-icon size="16" v-on="on">fa fa-info-circle</v-icon>
                              </template>
                              <span>{{ $t("hint_" + right) }}</span>
                            </v-tooltip>
                          </td>
                          <td :id="role.name+'RoleCol'" v-for="role in roles" :key="right + '_' + role.id">
                            <div class="checkbox">
                              <input type="checkbox" :checked="role.rights.includes(right)" @click="assignOrRemoveRight(role.id, right)">
                              <label @click="assignOrRemoveRight(role.id, right)"></label>
                            </div>
                          </td>
                        </tr>
                        <tr v-if="$rights.includes('TENANT_INDEPENDENCE')">
                          <td>
                            {{ $t("access_to_tenants") }}
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-icon size="16" v-on="on">fa fa-info-circle</v-icon>
                              </template>
                              <span>{{ $t("hint_access_tenants") }}</span>
                            </v-tooltip>
                          </td>
                          <td v-for="role in roles" :key="role.id" style="max-width: 90px">
                            <v-autocomplete class="custom-autocomplete" :items="viewableTenantsByRole[role.id]" v-model="role.viewableTenants" hide-details="auto" dense :search-input.sync="tenantSearchMap[role.id]" @keyup="fetchViewableTenants(tenantSearchMap[role.id], role)" item-text='name' item-value='id' multiple chips small-chips @change="saveSelectedViewableTenants(role)">
                              <template v-slot:append>
                                <v-icon>fas fa-chevron-down</v-icon>
                              </template>
                              <template v-slot:no-data>
                                <div class="px-4">{{ $t("enter_search_text") }}</div>
                              </template>
                              <template v-slot:selection="data">
                                  <v-chip
                                      v-if="role.viewableTenants.length < 2"
                                      v-bind="data.attrs"
                                      small
                                  >
                                      {{ data.item.name }}
                                  </v-chip>
                                  <div v-else-if="data.index === 0">
                                      {{ $t("selected_items", {count: role.viewableTenants.length}) }}
                                  </div>
                              </template>
                              <template v-slot:item="{item}">
                                {{item.name}}
                              </template>
                            </v-autocomplete>
                          </td>
                        </tr>
                        <tr>
                          <td>&nbsp;</td>
                          <td v-for="role in roles" :key="role.id" scope="col">
                            <button :id="role.name + 'EditButton'" class="btn btn-light mr-2" v-show="role.name != 'Admin'" @click="openEditModal(role)"> <edit-icon size="16"></edit-icon></button>
                            <button :id="role.name + 'DeleteButton'" class="btn btn-danger" v-show="role.name != 'Admin'" @click="openDeleteModal(role)"> <trash-icon size="16"></trash-icon></button>
                          </td>
                        </tr>
                        <!-- <tr v-for="role in roles" :key="role.id">
                          <td>{{user.firstname}}</td>
                          <td>{{user.lastname}}</td>
                          <td>{{user.email}}</td>
                          <td>{{user.roles}}</td>
                          <td>{{user.position}}</td>
                          <td><button class="btn btn-primary mr-2">Default Button</button> <button class="btn btn-primary"><i class="fa fa-camera"></i></button></td>
                        </tr> -->
                      </tbody>
                    </table>
                  </div>
                </div>
          </div>
        </div>
      </div>
      <!-- Container-fluid Ends-->
    


    <!-- Edit Modal -->
    <div class="modal fade show" v-show="showEditModal" tabindex="-1" aria-labelledby="exampleModalCenter" style="display: block; padding-right: 17px; background: rgba(0,0,0,.5);" aria-modal="true" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 v-if="editedRole.id == null" class="modal-title">{{$t('create_role')}}</h5>
            <h5 v-else class="modal-title">{{$t('edit_role')}}</h5>
            <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close" data-bs-original-title="" title="" @click="(showEditModal = false)"></button>
          </div>
          <div class="modal-body">
            <label class="form-label" for="roleName">{{$t('designation')}}</label>
            <input class="form-control" id="roleName" type="text" placeholder="Administrator" v-model="editedRole.name" @keypress.enter="saveChanges()">
          </div>
          <div class="modal-footer">
            <button @click="(showEditModal = false)" class="btn btn-secondary" type="button" data-bs-dismiss="modal" data-bs-original-title="" title="">{{$t('cancel')}}</button>
            <button @click="saveChanges()" class="btn btn-primary" type="button" data-bs-original-title="" title="">{{$t('save')}}</button>
          </div>
        </div>
      </div>
    </div>


    <!-- Delete Modal -->
    <div class="modal fade show" v-show="showDeleteConfirmation" tabindex="-1" aria-labelledby="exampleModalCenter" style="display: block; padding-right: 17px; background: rgba(0,0,0,.5);" aria-modal="true" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 v-if="editedRole.id == null" class="modal-title">{{$t('create_role')}}</h5>
            <h5 v-else class="modal-title">{{$t('delete_role')}}</h5>
            <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close" data-bs-original-title="" title="" @click="(showDeleteConfirmation = false)"></button>
          </div>
          <div class="modal-body">
            <p>{{$t('confirm_delete_role')}}</p>
          </div>
          <div class="modal-footer">
            <button @click="(showDeleteConfirmation = false)" class="btn btn-secondary" type="button" data-bs-dismiss="modal" data-bs-original-title="" title="">{{$t('cancel')}}</button>
            <button @click="deleteRole(deletingRole)" class="btn btn-danger" type="button" data-bs-original-title="" title="">{{$t('delete')}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CheckIcon, TrashIcon, EditIcon } from 'vue-feather-icons'

export default {
  components: {
    CheckIcon,
    TrashIcon,
    EditIcon
  },
  data() {
    return {
      routes: {
        roles: "/api/role",
        rights: "/api/role/rights",
        variableRights: "/api/role/variableRights/",
      },
      rights: [],
      roles: [],

      editedRole: {
        id: null,
        name: "",
        rights: [],
      },

      deletingRole: {
        id: null,
        name: "",
      },

      tenantContextId : null,
      tenantSearch: "",
      tenants: [],

      confirmation: {
        text: "",
        action: null,
        shown: false,
      },

      viewableTenantsByRole: {},
      tenantSearchMap: {},

      showEditModal: false,
      showDeleteConfirmation: false,
      createRoleName: "",
    };
  },

  watch: {
    tenantContextId: function(newValue, oldValue) {
      if(newValue != null){
        this.fetchRoles();
      }
    },
  },

  async mounted() {
    var _this = this;
    await this.$fetchCurrentUser().then(function(response){
      const user = response.data;
      _this.tenantContextId = user.tenantId;
      // TODO: Hier noch anpassen: Wenn SuperAdmin dann alle Mandanten, sonst nur Kinder-Mandanten
      if(user.superAdmin){
        _this.fetchTenants();
      }else{
        if(response.data.tenant != null && response.data.tenant.childrenTenants != null){
          _this.tenants = response.data.tenant.childrenTenants;
        }
      }
    });

    this.fetchRights();
    this.fetchRoles();
  },

  methods: {
    // Misc Methods
    onError(err) {
      if (err.response != null && err.response.data != null) {
        this.$noty.error(err.response.data);
      } else {
        console.error(err);
      }
    },
    // Fetch Methods
    fetchRoles() {
      var _this = this;
      const route = !this.$rights.includes("TENANT_INDEPENDENCE") ? "/api/role/tenant/" + this.tenantContextId : this.routes.roles;
      this.$axios
        .get(route)
        .then(function (response) {
          _this.roles = response.data.content;
          for (let i = 0; i < _this.roles.length; i++) {
            const role = _this.roles[i];
            _this.viewableTenantsByRole[role.id] = role.viewableTenants;
            _this.tenantSearchMap[role.id] = "";
          }
          // if(_this.$user != null && _this.$user.tenant != null && (_this.tenants == null || _this.tenants.length == 0 )) _this.fetchTenants(_this.$user.tenant.name, _this.$user.tenantId)
        })
        .catch(this.onError);
    },
    fetchTenants(search = "", tenantToSet = null){
      if(search == null) return;
      var _this = this;
      this.$axios
        .get("/api/tenant?size=50&search=" + search)
        .then(function (response) {
          if(_this.tenants.length == 0){
            _this.tenants = response.data.content;
          }
          for (let i = 0; i < response.data.content.length; i++) {
              const tenant = response.data.content[i];
              if(_this.tenants.filter(t => { return t.name == tenant.name }).length == 0){
                _this.tenants.push(tenant);
              }
            }
          if(tenantToSet != null) _this.tenantContextId = tenantToSet;
          _this.$forceUpdate();
        })
        .catch(this.onError);
    },

    fetchViewableTenants(search = "", role){
      if(search == null) return;
      var _this = this;
      this.$axios
        .get("/api/tenant?size=50&search=" + search)
        .then(function (response) {
          _this.viewableTenantsByRole[role.id] = response.data.content;
          _this.$forceUpdate();
        })
        .catch(this.onError);
    },

    fetchRights() {
      var _this = this;
      this.$axios
        .get(this.routes.rights)
        .then(function (response) {
          _this.rights = [];
          for (let i = 0; i < response.data.length; i++) {
            const right = response.data[i];
            _this.rights.push(right);
          }
        })
        .catch(this.onError);
    },

    saveChanges(){
      if(this.editedRole == null) {
        console.error("Änderungen können nicht gespeichert werden. Es liegen Programmfehler vor.");
        return;
      }
      if(this.editedRole.name == null || this.editedRole.name == "") return;
      if(this.editedRole.id == null){
        this.createRole();
        return;
      }
      this.updateRole();
    },

    // Save and Update Methods
    createRole() {
      var _this = this;
      if(this.$rights.includes("TENANT_INDEPENDENCE")) this.editedRole.tenantId = this.tenantContextId;
      progressIndicator.hidden = false;
      this.$axios
        .post(this.routes.roles, this.editedRole)
        .then(function (response) {
          _this.$noty.success(_this.$t("role_created"));
          _this.fetchRoles();
          _this.resetEditedRole();
          _this.showEditModal = false;
        })
        .catch(this.onError)
        .finally(this.$finalize);
    },
    
    assignOrRemoveRight(roleId, right) {
      var _this = this;
      this.$axios
        .put(this.routes.roles + "/" + roleId + "/right/" + right, {})
        .then(function (response) {
          _this.fetchRoles();
        })
        .catch(this.onError);
    },
    updateRole() {
      var _this = this;
      progressIndicator.hidden = false;
      this.$axios
        .put(this.routes.roles + "/" + this.editedRole.id, this.editedRole)
        .then(function (response) {
          _this.$noty.success(_this.$t("role_updated"));
          _this.fetchRoles();
          _this.resetEditedRole();
          _this.showEditModal = false;
        })
        .catch(this.onError)
        .finally(this.$finalize);
    },
    deleteRole(role) {
      var _this = this;
      progressIndicator.hidden = false;
      this.$axios
        .delete(this.routes.roles + "/" + role.id)
        .then(function (response) {
          _this.$noty.success(_this.$t("role_deleted"));
          _this.fetchRoles();
          _this.showDeleteConfirmation = false;
        })
        .catch(this.onError)
        .finally(this.$finalize);
    },

    showConfirmation(text, action) {
      this.confirmation.text = text;
      this.confirmation.action = action;
      this.confirmation.shown = true;
    },
    openEditModal(role){
      this.resetEditedRole();
      if(role != null) this.editedRole = Object.assign(this.editedRole, role);
      this.showEditModal = true;
    },
    openDeleteModal(role){
      this.deletingRole = role;
      this.showDeleteConfirmation = true;
    },
    checkRoleVariableRight(role, variableRight){
      for (let i = 0; i < role.variableRights.length; i++) {
        const assignedVariableRight = role.variableRights[i];
        if(assignedVariableRight.id == variableRight.id) return true;
      }
      return false;
    },
    saveSelectedViewableTenants(role){
      var _this = this;
      this.$axios
        .put(this.routes.roles + "/" + role.id + "/viewable-tenants", role)
        .then(function (response) {
          _this.fetchRoles();
          _this.resetEditedRole();
        })
        .catch(this.onError)
        .finally(this.$finalize);
    },
    resetEditedRole() {
      this.editedRole = {
        id: null,
        name: "",
        rights: [],
      };
    },
  },
};
</script>

<style>
.stickyheader-table{
  width: 100%;
  caption-side: bottom;
  border-collapse: collapse;
}
.stickyheader-table thead tr{
  position: sticky;
  top: 82px;
  z-index: 100;
  background-color: white;
}
</style>