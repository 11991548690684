<template>
    <div>
          <div class="container-fluid">
            <div class="page-header">
              <div class="row">
                <div class="col-sm-6">
                  <h3>{{$t('tenant management')}}</h3>
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="index.html">{{$t('management')}}</a></li>
                    <li class="breadcrumb-item active">{{$t('tenant management')}}</li>
                  </ol>
                </div>
                <div class="col-sm-6">
                  <!-- Bookmark Start-->
                  <div class="bookmark">
                    <ul>
                      <li><a href="javascript:void(0)" data-container="body" data-bs-toggle="popover" data-placement="top" title="" data-original-title="Tables"><i data-feather="inbox"></i></a></li>
                      <li><a href="javascript:void(0)" data-container="body" data-bs-toggle="popover" data-placement="top" title="" data-original-title="Chat"><i data-feather="message-square"></i></a></li>
                      <li><a href="javascript:void(0)" data-container="body" data-bs-toggle="popover" data-placement="top" title="" data-original-title="Icons"><i data-feather="command"></i></a></li>
                      <li><a href="javascript:void(0)" data-container="body" data-bs-toggle="popover" data-placement="top" title="" data-original-title="Learning"><i data-feather="layers"></i></a></li>
                      <li><a href="javascript:void(0)"><i class="bookmark-search" data-feather="star"></i></a>
                        <form class="form-inline search-form">
                          <div class="form-group form-control-search">
                            <input type="text" placeholder="Search..">
                          </div>
                        </form>
                      </li>
                    </ul>
                  </div>
                  <!-- Bookmark Ends-->
                </div>
              </div>
            </div>
          </div>
          <!-- Container-fluid starts-->
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <div class="card">
                  <div class="card-header pb-0">
                    <h5 v-if="editMode">{{$t('edit_tenant')}}</h5>
                    <h5 v-else>{{$t('create_tenant')}}</h5>
                  </div>
                  <div class="card-body">
                    <h6 style="margin-top: 10px;color: rgb(36, 105, 92);">{{$t('general_setting')}}</h6><br>
                    <div class="form theme-form">
                      <div class="row">
                        <div class="col">
                          <div class="mb-3">
                            <label>{{$t('designation')}}</label>
                            <input id="tenantDesignation" class="form-control" type="text" v-model="tenant.name">
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <div class="mb-3">
                            <label>{{$t('contact_email')}}</label>
                            <input id="tenantEmail" class="form-control" type="text" v-model="tenant.contactEmail">
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <div class="mb-3">
                            <label>{{$t('street')}}</label>
                            <input id="tenantStreet" class="form-control" type="text" v-model="tenant.street">
                          </div>
                        </div>
                        <div class="col">
                          <div class="mb-3">
                            <label>{{$t('number')}}</label>
                            <input id="tenantStreetNr" class="form-control" type="text" v-model="tenant.hnr">
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col py-0">
                          <div class="mb-3">
                            <label>{{$t('postal code')}}</label>
                            <input id="tenantZip" class="form-control" type="text" v-model="tenant.zip">
                          </div>
                        </div>
                        <div class="col py-0">
                          <div class="mb-3">
                            <label>{{$t('location')}}</label>
                            <input id="tenantLocation" class="form-control" type="text" v-model="tenant.city">
                          </div>
                        </div>
                        <div class="col py-0">
                          <div class="mb-3">
                            <label>{{$t('country')}}</label>
                            <input id="tenantCountry" class="form-control" type="text" v-model="tenant.country">
                          </div>
                        </div>
                      </div>
                      <button style="float: right;" @click="save()" class="btn btn-primary mt-2" type="button" data-bs-original-title="" title="">{{$t('save')}}</button>
                    <button style="float: right;" @click="$router.push('tenants')" class="btn btn-secondary mt-2 mr-2" type="button" data-bs-original-title="" title="">{{$t('cancel')}}</button>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <!-- Container-fluid Ends-->
  
    </div>
</template>

<script>
  import VueApexCharts from 'vue-apexcharts'
  import { MapPinIcon,PlusSquareIcon,ExternalLinkIcon } from 'vue-feather-icons'
  import VueGoogleAutocomplete from "vue-google-autocomplete";

  export default {

    props: {
      tenantId: Number,
    },
    
    components: {
      apexcharts: VueApexCharts,
      MapPinIcon,
      PlusSquareIcon,
      ExternalLinkIcon,
      VueGoogleAutocomplete,
    },
    data() {
      return {
        editMode: false,
        showRemoveApiKeyConfirmation: false,

        tenant: {
          name: "",
          street: "",
          hnr: "",
          zip: "",
          city: "",
          contactEmail: "",
          country: "GERMANY",
          type: "company",
          image: null,
          childrenTenantIds: [],
        },

        apiKey: "",

        tenants: [],

        customToolbar: [
          [{ header: [false, 1, 2, 3, 4, 5, 6] }],
          ["bold", "italic", "underline", "strike"], // toggled buttons
          // [
          //   { align: "" },
          //   { align: "center" },
          //   { align: "right" },
          //   { align: "justify" },
          // ],
          // ["blockquote", "code-block"],
          [{ list: "ordered" }, { list: "bullet" }],
          // [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          ["link","image"],
          // ["clean"],
        ],
      };
    },
  
    mounted() {
      if (this.tenantId != null) {
        this.editMode = true;
        this.fetchEditingTenant();
      }else{
        this.fetchTenants();
      }
    },
  
    methods: {
      fetchEditingTenant(){
        var _this = this;
        this.$axios
          .get("/api/tenant/" + this.tenantId)
          .then(function (response) {
            _this.tenant = response.data;
            _this.tenant.childrenTenantIds = [];
            // Apply Children Information
            _this.tenants = _this.tenant.childrenTenants;
            for (let i = 0; i < _this.tenant.childrenTenants.length; i++) {
              const tenant = _this.tenant.childrenTenants[i];
              _this.tenant.childrenTenantIds.push(tenant.id);
            }
          })
          .catch(_this.$onError);
      },
      fetchTenants(search = ""){
        if(search == null) return;
        var _this = this;
        progressIndicator.hidden = false;
        this.$axios
          .get("/api/tenant?size=50&search=" + search)
          .then(function (response) {
            if(_this.tenants.length == 0) _this.tenants = response.data.content;
            for (let i = 0; i < response.data.content.length; i++) {
              const tenant = response.data.content[i];
              if(_this.tenants.filter(t => { return t.name == tenant.name }).length == 0){
                _this.tenants.push(tenant);
              }
            }

            if(_this.tenantId != null){
              // Remove Tenant which is being edited (no self-adding)
              for (let i = _this.tenants.length - 1; i >= 0; i--) {
                const tenant = _this.tenants[i];
                if(tenant.id == _this.tenantId){
                  _this.tenants.splice(i, 1);
                }
              }
            }
            _this.$forceUpdate();
          })
          .catch(_this.$onError)
          .finally(this.finalize);
      },
      save(){
        var tenant = Object.assign({}, this.tenant);
        if(Array.isArray(tenant.image)){
          if(tenant.image.length == 0){
            tenant.image = null;
          }else{
            tenant.image = tenant.image[0];
          }
        }
        if(tenant.useCustomImage && (tenant.image == null || tenant.image == "")){
          this.$noty.error("Bitte laden Sie Ihr Wappen hoch, wenn Sie es in der App darstellen möchten");
          return;
        }
        if(!tenant.useCustomImage) tenant.image = null;
        if(this.editMode){
          this.updateTenant(tenant);
        }else{
          this.createTenant(tenant);
        }
      },
      createTenant(tenant){
        var _this = this;
        this.$axios
          .post("/api/tenant", tenant)
          .then(function (response) {
            _this.$noty.success(_this.$t('tenant_created'));
            _this.$router.push("/tenants");
          })
          .catch(_this.$onError);
      },
      updateTenant(tenant){
        // console.log(tenant)
        var _this = this;
        this.$axios
          .put("/api/tenant/" + this.tenantId, tenant)
          .then(function (response) {
            _this.$noty.success(_this.$t('tenant_updated'));
            _this.$router.push("/tenants");
          })
          .catch(_this.$onError);
      },
      handleImageAddedEditor(file, Editor, cursorLocation, resetUploader) {
        var formData = new FormData();

        formData.append("file", file);

        this.$axios
          .post("/files/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function (response) {
            var link = "/files/images/" + response.data;

            Editor.insertEmbed(cursorLocation, "image", link);

            resetUploader();
          });
      },
      handleImageDeletedEditor(image) {
        if (image.startsWith("/files/images/")) image = image.substring(14);

        this.$axios
          .delete("/files/upload/" + image)
          .then(function (response) {})
          .catch(function (err) {
            console.error(err);
          });
      },
      townhallMapClicked(evt) {
        this.addMarker(evt);
      },
      addMarker(event) {
        if (this.townhallMap.markers.length >= 1) {
          this.townhallMap.markers.splice(0, 1);
        }
        this.townhallMap.markers.push(event.latlng);
        this.tenant.townhallLongitude = event.latlng.lng;
        this.tenant.townhallLatitude = event.latlng.lat;
      },
      removeMarker() {
        this.townhallMap.markers.splice(0, 1);
        this.tenant.townhallLongitude = null;
        this.tenant.townhallLatitude = null;
      },
      googleAutocompleteChanged(data) {
        if (this.townhallMap.markers.length >= 1) {
          this.removeMarker();
        }
        var coordinates = {
          lat: data.latitude,
          lng: data.longitude,
        };
        this.townhallMap.markers.push(coordinates);
        this.tenant.townhallLongitude = data.longitude;
        this.tenant.townhallLatitude = data.latitude;
        var _this = this;
        Vue.nextTick(() => {
          _this.townhallMap.center = {
            latitude: data.latitude,
            longitude: data.longitude,
          };
          _this.townhallMap.zoom = 16;
          _this.$refs.townhallMap.mapObject.panTo(_this.townhallMap.center);
        });
      },
      logError(error) {
        console.log(error);
      },
      finalize(){
        progressIndicator.hidden = true;
      }
    },
  };
  </script>
  
  <style>
  </style>