<template>
  <div>
        <div class="container-fluid">
          <div class="page-header">
            <div class="row">
              <div class="col-sm-6">
                <h3>{{$t('user management')}}</h3>
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="index.html">{{$t('management')}}</a></li>
                  <li class="breadcrumb-item active">{{$t('user management')}}</li>
                </ol>
              </div>
              <div class="col-sm-6">
                <!-- Bookmark Start-->
                <div class="bookmark">
                  <ul>
                    <li><a href="javascript:void(0)" data-container="body" data-bs-toggle="popover" data-placement="top" title="" data-original-title="Tables"><i data-feather="inbox"></i></a></li>
                    <li><a href="javascript:void(0)" data-container="body" data-bs-toggle="popover" data-placement="top" title="" data-original-title="Chat"><i data-feather="message-square"></i></a></li>
                    <li><a href="javascript:void(0)" data-container="body" data-bs-toggle="popover" data-placement="top" title="" data-original-title="Icons"><i data-feather="command"></i></a></li>
                    <li><a href="javascript:void(0)" data-container="body" data-bs-toggle="popover" data-placement="top" title="" data-original-title="Learning"><i data-feather="layers"></i></a></li>
                    <li><a href="javascript:void(0)"><i class="bookmark-search" data-feather="star"></i></a>
                      <form class="form-inline search-form">
                        <div class="form-group form-control-search">
                          <input type="text" placeholder="Search..">
                        </div>
                      </form>
                    </li>
                  </ul>
                </div>
                <!-- Bookmark Ends-->
              </div>
            </div>
          </div>
        </div>
        <!-- Container-fluid starts-->
        <div class="container-fluid">
          <div class="row">
            <div class="col-6">
              <div class="card">
                <div class="card-header pb-0">
                  <h5 v-if="editMode">{{$t('edit_user')}}</h5>
                  <h5 v-else>{{$t('create_user')}}</h5>
                </div>
                <div class="card-body">
                  <div class="form theme-form">
                    <div class="row">
                      <div class="col">
                        <div class="mb-3">
                          <label>{{$t('firstname')}}</label>
                          <input class="form-control" type="text" v-model="user.firstname" placeholder="Maxima">
                        </div>
                      </div>
                      <div class="col">
                        <div class="mb-3">
                          <label>{{$t('lastname')}}</label>
                          <input class="form-control" type="text" v-model="user.lastname" placeholder="Mustermann">
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col py-0">
                        <div class="mb-3">
                          <label>{{$t('email')}}</label>
                          <input class="form-control" type="text" v-model="user.email" placeholder="muster@beispiel.de">
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col py-0">
                        <div class="mb-3">
                          <label>{{$t('position')}}</label>
                          <input class="form-control" type="text" v-model="user.position" placeholder="Mediengestaltung">
                        </div>
                      </div>
                    </div>
                    <div class="row" v-if="$rights.includes('TENANT_INDEPENDENCE')">
                      <div class="col py-0">
                        <div class="mb-3">
                          <label>{{$t('tenant')}}</label>
                          <v-autocomplete class="custom-autocomplete" :items="tenants" v-model="user.tenantId" hide-details="auto" dense :search-input.sync="tenantSearch" @keyup="fetchTenants(tenantSearch)" item-text='name' item-value='id'>
                            <template v-slot:append>
                              <v-icon>fas fa-chevron-down</v-icon>
                            </template>
                            <template v-slot:selection="{item}">
                              {{item.name}}
                            </template>
                            <template v-slot:item="{item}">
                              {{item.name}}
                            </template>

                          </v-autocomplete>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col py-0">
                        <div class="mb-3">
                          <label>{{$t('role')}}</label>
                          <v-autocomplete class="custom-autocomplete" :items="roles" v-model="user.role" hide-details="auto" dense :search-input.sync="search" item-text='name' item-value='id'>
                            <template v-slot:append>
                              <v-icon>fas fa-chevron-down</v-icon>
                            </template>
                            <template v-slot:selection="{item}">
                              {{item.name}}
                            </template>
                            <template v-slot:item="{item}">
                              {{item.name}}
                            </template>

                          </v-autocomplete>
                        </div>
                      </div>
                    </div>
                    <br>
                    <button style="float: right;" @click="save()" class="btn btn-primary" type="button" data-bs-original-title="" title="">Speichern</button>
                    <button class="btn btn-secondary mr-2" style="float: right;" @click="$routerBack()">Abbrechen</button>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-6">
              <div class="card">
                <div class="card-header pb-0">
                  <h5>{{$t('actions')}}</h5>
                </div>
                <div class="card-body" v-if="editMode">
                  <button @click="resetPassword()" class="btn btn-light mb-2" type="button" data-bs-original-title="" title="">{{$t('reset_password')}}</button><br>
                  <button @click="showDeleteConfirmation = true" class="btn btn-danger" type="button" data-bs-original-title="" title="">{{$t('delete_user')}}</button>
                </div>
                <div class="card-body" v-else>
                  <p>{{$t('save_user_first')}}</p>
                </div>
              </div>
            </div>

          </div>
        </div>
        <!-- Container-fluid Ends-->


    <!-- Delete Modal -->
    <ConfirmDialog v-model="showDeleteConfirmation" :headline="$t('delete_user')" :text="$t('confirm_delete_user')" @actionClicked="deleteUser()" />
    <!-- <div class="modal fade show" v-show="showDeleteConfirmation" tabindex="-1" aria-labelledby="exampleModalCenter" style="display: block; padding-right: 17px; background: rgba(0,0,0,.5);" aria-modal="true" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{$t('delete_user')}}</h5>
            <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close" data-bs-original-title="" title="" @click="(showDeleteConfirmation = false)"></button>
          </div>
          <div class="modal-body">
            <p>{{$t('confirm_delete_user')}}</p>
          </div>
          <div class="modal-footer">
            <button @click="(showDeleteConfirmation = false)" class="btn btn-secondary" type="button" data-bs-dismiss="modal" data-bs-original-title="" title="">{{$t('cancel')}}</button>
            <button @click="deleteUser()" class="btn btn-danger" type="button" data-bs-original-title="" title="">{{$t('delete')}}</button>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>
  
<script>
  import { MapPinIcon,PlusSquareIcon } from 'vue-feather-icons'

  export default {

    props: {
      userId: Number,
    },
    
    components: {
      MapPinIcon,
      PlusSquareIcon, 
    },
    data() {
      return {
        editMode: false,
        showDeleteConfirmation: false,
        tenantSearch: "",

        categorySearchText: "",

        tenants: [],
        roles: [],

        categories: [],
        assignedCategories: [],

        user: {
          email: "",
          firstname: "",
          lastname: "",
          position: "",
          tenantId: null,
          role: null,
        },
      };
    },

    watch: {
      'user.tenantId': function(newValue, oldValue) {
        if(newValue == null){
          this.fetchRoles(this.$user.tenantId);
        }else{
          this.fetchRoles(newValue);
        }
        this.fetchCategories(newValue);
        this.assignedCategories = [];
      },
    },
  
    mounted() {
      if (this.userId != null && this.userId > 0) {
        this.editMode = true;
        this.fetchEditingUser();
      }else{
        if(this.$user){
          this.user.tenantId = this.$user.tenantId;
        }
        this.fetchTenants();
      }
      if(this.$user){
        this.fetchRoles(this.$user.tenantId);
      }
    },
  
    methods: {
      onError(err) {
        if (
          err.response != null &&
          err.response.data != null
        ) {
          this.$noty.error(err.response.data);
        } else {
          console.error(err);
        }
      },
      fetchEditingUser(){
        var _this = this;
        this.$axios
          .get("/api/user/" + this.userId)
          .then(function (response) {
            _this.user = response.data;
            if(_this.user.tenant != null) _this.fetchTenants(_this.user.tenant.name);
            if(response.data.roles != null && response.data.roles.length > 0) _this.user.role = response.data.roles[0].id;
          })
          .catch(this.onError);
      },
      fetchTenants(search = ""){
        if(search == null) return;
        var _this = this;
        this.$axios
          .get("/api/tenant?size=50&search=" + search)
          .then(function (response) {
            _this.tenants = response.data.content;
          })
          .catch(this.onError);
      },
      fetchRoles(tenantId){
        if(tenantId == null) return;
        var _this = this;
        this.$axios
          .get("/api/role/tenant/" + tenantId)
          .then(function (response) {
            _this.roles = response.data.content;
          })
          .catch(this.onError);
      },
      save(){
        const error = this.$t("please_fill_out_all_mandatory_fields");
        if(this.user.email == "" || this.user.firstname == "" || this.user.lastname == ""){
          this.$noty.error(error);
          return;
        }
        var dto = Object.assign({}, this.user);
        dto.roles = "";
        if(this.user.role != null){
          dto.roles += dto.role;
        }
        dto.active = true;
        
        if(!this.editMode){
          this.createUser(dto);
        }else{
          this.updateUser(dto);
        }
      },
      createUser(dto){
        var _this = this;
        progressIndicator.hidden = false;
        this.$axios
          .post("/api/user", dto)
          .then(response => {
          })
          .then(function(response) {
            _this.$noty.success(_this.$t('user_created',{name: name}));
            _this.$router.push("/users");
          })
          .catch(this.onError)
          .finally(this.$finalize);
      },
      async updateUser(dto){
        var _this = this;
        progressIndicator.hidden = false;

        this.$axios
          .put("/api/user/" + this.userId, dto)
          .then(response => {
          })
          .then(function (response) {
            _this.$noty.success(_this.$t('user_updated',{name: name}));
            _this.$router.push("/users");
          })
          .catch(this.onError)
          .finally(this.$finalize);
      },
      resetPassword(){
        var _this = this;
        progressIndicator.hidden = false;
        this.$axios
          .put("/api/user/" + this.userId + "/send-password-reset", {})
          .then(function (response) {
            _this.$noty.success(response.data);
            _this.$router.push("/users");
          })
          .catch(this.onError)
          .finally(this.$finalize);
      },
      deleteUser(){
        var _this = this;
        const name = this.user.firstname + " " + this.user.lastname;
        progressIndicator.hidden = false;
        this.$axios
          .delete("/api/user/" + this.userId, {})
          .then(function (response) {
            _this.$noty.success(_this.$t("user_deleted", {name: name}));
            _this.$router.push("/users");
          })
          .catch(this.onError)
          .finally(this.$finalize);
      },
      logError(error) {
        console.log(error);
      },
    },
  };
  </script>
  
  <style scoped>
    
  </style>