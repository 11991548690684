<template>
<div>
  <div class="side-background" style="background-image: url('/static/images/bg.webp')">
      <div class="side-background-foreground">
        <div class="container-fluid p-0">
        <div class="row">
          <div class="col-12 p-0">
            <div class="login-card">
              <div class="theme-form login-form">
                <div class="logo-wrapper d-flex justify-content-center"><img class="img-fluid" src="/static/images/logo/logo.svg" alt=""></div>
                <div class="form-group">
                  <label>{{$t('new_password')}}</label>
                  <input class="form-control" type="password" required="" v-model="resetPassword.password" @keypress.enter="savePassword()">
                </div>
                <div class="form-group">
                  <label>{{$t('confirm_password')}}</label>
                  <input class="form-control" type="password" v-model="resetPassword.passwordRepeat" @keypress.enter="savePassword()" required="">
                </div>
                <div class="form-group row">
                  <div class="col-6">
                    <button @click="$router.push('/login')" class="btn btn-secondary" type="button">{{$t('cancel')}}</button>
                  </div>
                  <div class="col-6">
                    <button style="float: right;" class="btn btn-primary-light btn-block" @click="savePassword()"><a href="javascript:void(0)">{{$t('save_password')}}</a></button>
                  </div>
                </div>
                <!-- <p>Don't have account?<a class="ms-2" href="log-in.html">Create Account</a></p> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
</div>
</template>

<script>
export default {

    props: {
      token: String,
    },

    data() {
      return {
        resetPassword: {
            password: null,
            passwordRepeat: null,
        }
      }
    },

    mounted(){
      if(this.token == null){
        this.$noty.error(this.$t("password_reset_token_missing"));
      }
    },
    
    methods: {

      onError(err) {
        if (err.response != null && err.response.data != null) {
          this.$noty.error(err.response.data);
        } else {
          console.error(err);
        }
      },

      savePassword() {
        var _this = this;
        var resetPassword = {};
        resetPassword = Object.assign(resetPassword, this.resetPassword);
        resetPassword.token = this.token;

        if(this.$user != null){
          this.$noty.error(this.$t("already_logged_in"));
          return;
        }
      
        if(resetPassword.password == null){
          this.$noty.error(this.$t("empty_value", {name: this.$t("new_password")}));
          return;
        }
        if(resetPassword.passwordRepeat == null){
          this.$noty.error(this.$t("empty_value", {name: this.$t("confirm_password")}));
          return;
        }
        if(resetPassword.token == null){
          this.$noty.error(this.$t("password_reset_token_missing"));
          return;
        }
        if(resetPassword.passwordRepeat != resetPassword.password){
          this.$noty.error(this.$t("passwords_not_equal"));
          return;
        }

        this.$axios
            .post("/api/user/reset-password", resetPassword)
            .then(function (response) {
              _this.$noty.success(_this.$t("password_resetted"));
              _this.$router.push("/login");
            })
            .catch(this.onError);
      },

      onFinally(){
        showLoadingCircle(false);
      },

    }
}
</script>