<template>
  <div id="app">
    <v-app>
      <div class="page-wrapper compact-wrapper" id="pageWrapper">
        <!-- Page Header Start-->
        <div class="page-main-header" v-if="$user != null">
          <div class="main-header-right row m-0">
            <div class="main-header-left">
              <div class="logo-wrapper"><a @click="$router.push('')"><img class="img-fluid"
                    src="/static/images/logo/crmsystem-logo.png" alt=""></a></div>
              <div class="dark-logo-wrapper"><a href="index.html"><img class="img-fluid"
                    src="/static/images/logo/dark-logo.png" alt=""></a></div>
              <div @click="sidebar = !sidebar" class="toggle-sidebar"><align-center-icon size="1.5x"
                  class="status_toggle middle" data-feather="align-center custom-class"
                  id="sidebar-toggle"></align-center-icon> </div>
            </div>
            <!-- <div class="left-menu-header col">
            <ul class="mb-0">
              <li>
                <form class="form-inline search-form">
                  <div class="search-bg"><i class="fa fa-search"></i>
                    <input class="form-control-plaintext" v-model="globalSearch" placeholder="Suche...">
                  </div>
                </form><span class="d-sm-none mobile-search search-bg"><i class="fa fa-search"></i></span>
              </li>
            </ul>
          </div> -->
            <div class="nav-right col pull-right right-menu p-0">
              <ul class="nav-menus mb-0">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">

                    <li v-on="on"><a class="text-dark" href="#!" onclick="javascript:toggleFullScreen()"><maximize-icon
                          class="custom-class"></maximize-icon></a></li>

                  </template>
                  <span>{{ $t('fullscreen') }}</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">

                    <li v-on="on">
                      <v-select class="languagepicker" :menu-props="{ minWidth: '180' }" v-model="selectedlanguage"
                        :items="possiblelanguages" append-icon="" item-text="name" item-value="shortForm" hide-details
                        @input="setLanguage(selectedlanguage)">
                        <template slot="item" slot-scope="data">
                          <img style="width:26px;height:17px;vertical-align: unset;"
                            :src="'/files/flags/' + data.item.flag"> &nbsp; {{ data.item.name }}
                        </template>
                        <template slot='selection' slot-scope='{item}'>
                          <img style="width:26px;height:17px;vertical-align: unset;" :src="'/files/flags/' + item.flag">
                        </template>
                      </v-select>
                    </li>
                  </template>
                  <span>{{ $t('change_language') }}</span>
                </v-tooltip>
                <li class="onhover-dropdown p-0">
                  <button class="btn btn-primary-light" @click="logout()" type="button"><a
                      href="javascript:void(0)"><log-out-icon class="custom-class"></log-out-icon>Log out</a></button>
                </li>
              </ul>
            </div>
            <div class="d-lg-none mobile-toggle pull-right w-auto"><i data-feather="more-horizontal"></i></div>
          </div>
        </div>
        <div class="page-body-wrapper sidebar-icon">
          <!-- Page Sidebar Start-->
          <header v-bind:class="sidebar ? 'close_icon' : ''" class="main-nav" v-if="$user != null">
            <div class="sidebar-user text-center">
              <a href="#">
                <h6 class="mt-3 f-14 f-w-600">{{ $user.firstname }} {{ $user.lastname }}</h6>
              </a>
              <p class="mb-0 font-roboto">{{ $user.tenantName }}</p>
              <!-- <ul>
              <li><span><span class="counter">{{ stats.all }}</span></span>
                <p>Fälle</p>
              </li>
              <li><span>{{ stats.open }}</span>
                <p>Offen</p>
              </li>
              <li><span><span class="counter">{{ stats.rejected }}</span></span>
                <p>Abgelehnt</p>
              </li>
            </ul> -->
            </div>
            <nav>
              <div class="main-navbar">
                <div class="left-arrow" id="left-arrow"><i data-feather="arrow-left"></i></div>
                <div id="mainnav">
                  <ul class="nav-menu custom-scrollbar">
                    <li class="back-btn">
                      <div class="mobile-back text-end"><span>Back</span><i class="fa fa-angle-right ps-2"
                          aria-hidden="true"></i></div>
                    </li>
                      <li class="sidebar-main-title">
                        <div>
                          <h6>{{ $t("home") }}</h6>
                        </div>
                    </li>     
                    <!-- <li class="dropdown" v-if="$rights.includes('VIEW_DASHBOARD')"><a class="nav-link menu-title" @click="$router.push('/dashboard')"><home-icon class="custom-class"></home-icon><span>Dashboard</span></a></li> -->
                    <li class="dropdown" v-if="$rights.includes('VIEW_COMPANIES')"><a class="nav-link menu-title" @click="$router.push('/companies').catch(error => {
                      if (
                        error.name !== 'NavigationDuplicated' &&
                        !error.message.includes('Avoided redundant navigation to current location')
                      ) {
                        console.log(error)
                      }
                    })"><briefcase-icon class="custom-class"></briefcase-icon><span>{{ $t('companies') }}</span></a>
                    </li>

                    <li class="sidebar-main-title"
                      v-if="$rights.includes('VIEW_USER') || $rights.includes('MANAGE_CATEGORIES') || $rights.includes('MANAGE_ROLES') || $rights.includes('TENANT_INDEPENDENCE')">
                      <div>
                        <h6>{{$t('management')}}</h6>
                      </div>
                    </li>
                    <li class="dropdown" v-if="$rights.includes('VIEW_USER')"><a class="nav-link menu-title" @click="$router.push('/users').catch(error => {
                      if (
                        error.name !== 'NavigationDuplicated' &&
                        !error.message.includes('Avoided redundant navigation to current location')
                      ) {
                        console.log(error)
                      }
                    })"><users-icon class="custom-class"></users-icon><span>{{ $t('user management') }}</span></a></li>
                    <li class="dropdown" v-if="$rights.includes('MANAGE_ROLES')"><a class="nav-link menu-title" @click="$router.push('/roles').catch(error => {
                      if (
                        error.name !== 'NavigationDuplicated' &&
                        !error.message.includes('Avoided redundant navigation to current location')
                      ) {
                        console.log(error)
                      }
                    })"><grid-icon class="custom-class"></grid-icon><span>{{$t('role management')}}</span></a></li>
                    <li class="dropdown"
                      v-if="$rights.includes('TENANT_INDEPENDENCE') || $rights.includes('EDIT_OWN_TENANT')"><a
                        class="nav-link menu-title" @click="$router.push('/tenants').catch(error => {
                          if (
                            error.name !== 'NavigationDuplicated' &&
                            !error.message.includes('Avoided redundant navigation to current location')
                          ) {
                            console.log(error)
                          }
                        })"><briefcase-icon class="custom-class"></briefcase-icon><span>{{$t('tenants')}}</span></a></li>

                  </ul>
                </div>
                <div class="right-arrow" id="right-arrow"><i data-feather="arrow-right"></i></div>
              </div>
            </nav>
          </header>
          <!-- Page Sidebar Ends-->
          <div v-bind:class="[sidebar ? 'ml-0' : '', $user != null ? 'page-body' : '']" class="">
            <keep-alive include="Companies">
              <router-view></router-view>
            </keep-alive>
          </div>

        </div>

        <!-- Global Search Modal -->
        <div class="modal fade show" v-show="globalSearch != ''" tabindex="-1" aria-labelledby="exampleModalCenter"
          style="display: block; padding-right: 17px; background: rgba(0,0,0,.5);margin-top: 90px;padding-right: 0px;"
          aria-modal="true" role="dialog">
          <div class="modal-dialog" style="margin-top: 0px;margin-left: 288px;max-width: 100%" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <br v-if="$rights.includes('VIEW_USER')">
                <b v-show="$rights.includes('VIEW_USER')">{{ $t("users") }}</b>
                <div v-show="$rights.includes('VIEW_USER')">
                  <div class="table-responsive">
                    <table class="table">
                      <tbody>
                        <tr v-show="searchResultUsers.length == 0">
                          {{ $t("no_entries") }}
                        </tr>
                        <tr v-for="user in searchResultUsers" :key="user.id">
                          <td>{{ user.firstname }} {{ user.lastname }}</td>
                          <td>{{ user.email }}</td>
                          <td>{{ user.allRoles }}</td>
                          <td style="text-align: right;padding-right: 50px;"><a href="javascript:void(0)"
                              style="text-decoration: underline;"
                              @click="openSearchResult('/edit-user?userId=' + user.id)">{{ $t("details") }}</a></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="modal-footer">
                  <button @click="(globalSearch = '')" class="btn btn-secondary" type="button" data-bs-dismiss="modal"
                    data-bs-original-title="" title="">{{ $t("cancel") }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </v-app>
  </div>
</template>
<script>
import { SettingsIcon, LogOutIcon, RadioIcon, BookmarkIcon, AwardIcon, Volume2Icon, HomeIcon, AlignCenterIcon, ClipboardIcon, UsersIcon, CopyIcon, MaximizeIcon, StarIcon, BellIcon, MessageSquareIcon, GridIcon, BriefcaseIcon } from 'vue-feather-icons'

export default {
  components: {
    SettingsIcon,
    LogOutIcon,
    AwardIcon,
    RadioIcon,
    BookmarkIcon,
    HomeIcon,
    Volume2Icon,
    AlignCenterIcon,
    ClipboardIcon,
    UsersIcon,
    CopyIcon,
    MaximizeIcon,
    StarIcon,
    BellIcon,
    MessageSquareIcon,
    GridIcon,
    BriefcaseIcon,

  },

  name: "app",
  data() {
    return {
      sidebar: false,
      drawer: null,
      footdrawer: true,
      selectedlanguage: null,
      possiblelanguages: [],
      standardRoutes: [
        {
          key: "objects",
          name: this.$t("objects"),
          icon: "fas fa-bomb",
          to: "/findings",
        },
        {
          key: "masterdata",
          name: this.$t("masterdata"),
          icon: "fas fa-database",
          to: "/masterdata",
          right: "WriteMasterdata",
        },
        {
          key: "users",
          name: this.$t("users"),
          icon: "fas fa-user",
          to: "/users",
          right: "ReadUsers",
        },
        {
          key: "roles",
          name: this.$t("roles"),
          icon: "fas fa-user-tag",
          to: "/roles",
          right: "WriteUsers",
        },
      ],
      loginRoute: {
        key: "login",
        name: this.$t("login"),
        icon: "fas fa-user",
        to: "/login",
      },
      logoutRoute: {
        key: "logout",
        name: this.$t("logout"),
        icon: "fas fa-sign-out-alt",
      },
      stats: {
        all: 0,
        open: 0,
        created: 0,
        finished: 0,
        rejected: 0,
        inProgress: 0,
      },
      allowedRoutes: [],
      user: null,
      language: {
        language: null,
        flag: null,
        shortForm: null
      },
      languages: [],

      globalSearch: "",
      globalSearchMaxSize: 5,
      searchResultCases: [],
      searchResultBISInfos: [],
      searchResultUsers: [],
    };
  },

  mounted() {
    this.getCurrentUser();
    this.getCurrentLanguage();
    this.getLanguages();
    console.log(Vue.prototype.$user);
    this.blockDeviceWithPageParameter();

    jQuery('.submenu-title').append('<div class="according-menu"><i class="fa fa-angle-right"></i></div>');
  },

  computed: {
    mini() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
  },

  methods: {
    checkIfLoggedIn() {
      var _this = this;
      setTimeout(function () {
        _this.$axios
          .get("/api/user/me").then(function (response) {
            _this.checkIfLoggedIn();
          })
          .catch(err => {
            _this.$noty.error(_this.$t('logout_inactive'));
          });
      }, 60000)
    },
    getCurrentUser() {
      var _this = this;
      progressIndicator.hidden = false;
      this.$axios
        .get("/api/user/me").then(function (response) {
          _this.user = response.data;
          Vue.prototype.$user = _this.user;
          Vue.prototype.$rights = _this.user.allRights;
          _this.$forceUpdate();
          _this.checkIfLoggedIn();

          var windowLocation = window.location.href;
          windowLocation = window.location.href.substring(9);

          const currentPage = windowLocation.substring(windowLocation.indexOf("/"));
          console.log(currentPage);
          if (currentPage == "/" || currentPage == "/login") {
            _this.$router.push("/create-new-podcast");
          }

          // _this.allowedRoutes = [];
          // for (let i = 0; i < _this.standardRoutes.length; i++) {
          //   const route = _this.standardRoutes[i];
          //   if (route.right == null || _this.hasRight(route.right)) {
          //     _this.allowedRoutes.push(route);
          //   }
          // }

        })
        .catch(this.getCurrentUserFailed)
        .finally(this.requestEnded);
    },
    gotCurrentUser(response) {
      this.user = response.data;
      Vue.prototype.$user = this.user;
      this.$forceUpdate();
      Vue.prototype.$rights = this.user.allRights;

      this.allowedRoutes = [];
      for (let i = 0; i < this.standardRoutes.length; i++) {
        const route = this.standardRoutes[i];
        if (route.right == null || this.hasRight(route.right)) {
          this.allowedRoutes.push(route);
        }
      }
    },
    getCurrentUserFailed(error) {
      this.user = null;
      console.error(error);
    },
    onError(err) {
      if (
        err.response != null &&
        err.response.data != null &&
        err.response.data.message != null
      ) {
        this.$noty.error(err.response.data.message);
      } else {
        console.error(err);
      }
    },
    requestEnded() {
      progressIndicator.hidden = true;
    },

    getCurrentLanguage() {
      progressIndicator.hidden = false;
      var _this = this;
      this.$axios
        .get("/api/language/me")
        .then(function (response) {
          _this.language.language = response.data.language;
          _this.language.flag = response.data.flag;
          _this.selectedlanguage = response.data.shortForm;
        })
        .catch(this.onError)
        .finally(this.requestEnded);
    },

    getLanguages() {
      progressIndicator.hidden = false;
      var _this = this;
      this.$axios
        .get("/api/language/all")
        .then(function (response) {
          _this.languages = response.data;
          _this.convertLanguages(_this.languages);

          // Make function accessible for getting flag by language
          Vue.prototype.$languages = _this.languages;
        })
        .catch(this.onError)
        .finally(this.requestEnded);
    },
    convertLanguages(languages) {
      const lnlist = [];
      for (var i in languages) {
        var language = {};
        var ln = languages[i].language;

        // console.log(this.$t(languages[i].language));
        language.name = this.$t(languages[i].language);
        language.flag = languages[i].flag;
        language.shortForm = languages[i].shortForm;
        lnlist.push(language);

      }
      this.possiblelanguages = lnlist;
    },

    blockDeviceWithPageParameter() {
      var _this = this;
      var url = new URL(window.location.href);
      var blockDeviceId = url.searchParams.get("blockDeviceId");
      if (blockDeviceId == null) return;
      this.$axios
        .get("/api/device/" + blockDeviceId + "/block-device")
        .then(function (response) {
          _this.$noty.success(response.data);
        })
        .catch(e => {
          _this.$noty.error(e.response.data);
        });
    },

    setLanguage(shortForm) {
      if (!window.location.href.includes('?')) {
        window.location.href = window.location.href + "?lang=" + shortForm;
      } else {
        window.location.href = window.location.href + "&lang=" + shortForm;
      }
    },

    showCatalog() {
      var win = window.open("/api/downloads/catalog", "_blank");
      win.focus();
    },

    hasRight(right) {
      if (this.user.allRights.includes(right)) return true;
      return false;
    },

    toggleAuthentication() {
      if (this.user == null) {
        this.$router.push("login");
      } else {
        this.logout();
      }
    },
    logout() {
      this.$axios
        .post("/logout")
        .then(this.logoutDone)
        .catch(this.logoutFailed);
    },
    logoutDone(response) {
      Vue.prototype.$user = null;
      this.switchToLoginPage();
    },
    logoutFailed(error) {
      this.switchToLoginPage();
    },
    switchToLoginPage() {
      this.user = null;
      this.$axios.logout();
      this.$router.push("login");
    },
    nothing() { },

    fetchCases() {
      var _this = this;
      this.$axios
        .get("/api/case", {
          params: {
            search: this.globalSearch,
            page: 0,
            size: this.globalSearchMaxSize
          }
        })
        .then(function (response) {
          _this.searchResultCases = response.data.content;
        })
        .catch(this.onError);
    },
    fetchBISInfos() {
      var _this = this;
      this.$axios
        .get("/api/buergerinfo/info", {
          params: {
            search: this.globalSearch,
            page: 0,
            size: this.globalSearchMaxSize
          }
        })
        .then(function (response) {
          _this.searchResultBISInfos = response.data.content;
        })
        .catch(this.onError);
    },
    fetchUsers() {
      var _this = this;
      this.$axios
        .get("/api/user", {
          params: {
            search: this.globalSearch,
            page: 0,
            size: this.globalSearchMaxSize
          }
        })
        .then(function (response) {
          _this.searchResultUsers = response.data.content;
        })
        .catch(this.onError);
    },
    openSearchResult(link) {
      this.globalSearch = "";
      this.$router.push(link).catch(err => { });
    }
  },
  watch: {
    $route(to, from) {
      if (from.path === "/login" && to.path !== "/reset-password") {
        this.getCurrentUser();
      }
    },
    globalSearch(val) {
      if (this.globalSearch == "") {
        return;
      }
      this.fetchCases();
      this.fetchBISInfos();
      this.fetchUsers();
    }
  },
};
</script>
