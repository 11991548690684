<template>
  <div class="col-xl-12 right-side-block">
        <h3>{{ $t("contact") }}</h3>
        <div class="right-side divider"></div>
        <div class="mt-6"></div>
        <span class="font-blue font-bigger font-weight-bold">CodeGuides GmbH</span><br>
        <p>Horstfelder Dorfstraße 8<br>
        D-15806 Zossen<br><br>
        <a class="font-grey" href="mailto:info@codeguides.de">info@codeguides.de</a><br>
        <a class="font-grey" href="https://www.codeguides.de" target="_blank">www.codeguides.de</a></p>
        <span v-if="showBuildNumber && build_number != null" class="font-grey">{{ build_number }}</span>
  </div>
</template>

<script>
export default {

  props: {
    showBuildNumber: {
      type: Boolean,
      default: function(){
        return false;
      }
    },
  },

  data() {
    return {
      build_number: null,
    }
  },

  mounted(){
    if(this.showBuildNumber){
      this.fetchBuildNumber();
    }
  },

  methods: {
    fetchBuildNumber() {
      var _this = this;
      this.$axios
        .get("/api/app")
        .then(function (response) {
          _this.build_number = response.data;
        })
        .catch(this.onError);
    },
  }

}
</script>

<style>

</style>