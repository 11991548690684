var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],ref:"picker",staticClass:"form-control",attrs:{"placeholder":"Symbol suchen (englisch)"},domProps:{"value":(_vm.search)},on:{"blur":_vm.blur,"focus":_vm.focus,"input":function($event){if($event.target.composing)return;_vm.search=$event.target.value}}}),_c('div',{staticClass:"preview-container"},[_c('div',{staticClass:"icon-preview"},[_c('div',{staticClass:"icon-wrapper"},[(_vm.selected.free.includes('brands'))?_c('i',{class:'fab fa-' + _vm.selected.class}):(_vm.selected.free.includes('solid'))?_c('i',{class:'fas fa-' + _vm.selected.class}):(_vm.selected.free.includes('regular'))?_c('i',{class:'far fa-' + _vm.selected.class}):_vm._e()])])]),_c('transition',{attrs:{"name":"icon-preview-fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.focusOn),expression:"focusOn"}],staticClass:"preview-container"},[_c('div',{class:[
          'previewer',
          'rounded',
          { 'custom-shadow-sm': !_vm.hoverPanel },
          { 'custom-shadow': _vm.hoverPanel },
        ],on:{"click":function($event){return _vm.select(undefined)},"mouseover":function($event){_vm.hoverPanel = true},"mouseout":function($event){_vm.hoverPanel = false}}},[_c('div',{staticClass:"row"},_vm._l((_vm.iconsFiltered),function(i,index){return _c('div',{key:index,staticClass:"icon-preview col-3"},[_c('div',{class:[
                'icon-wrapper',
                'rounded',
                'shadow-sm',
                { selected: i.unicode == _vm.selected.unicode },
              ],on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.select(i)}}},[(i.free.includes('brands'))?_c('i',{class:'fab fa-' + i.class}):(i.free.includes('solid'))?_c('i',{class:'fas fa-' + i.class}):(i.free.includes('regular'))?_c('i',{class:'far fa-' + i.class}):_vm._e()])])}),0)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }