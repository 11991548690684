<template>
  <div class="col-xl-12 right-side-block">
    <h3>{{ headline }}</h3>
    <div class="right-side divider"></div>
    <div class="mt-6"></div>
    <v-btn href="https://www.linkedin.com/feed/" tile class="ma-2" min-width="36" width="36" height="36" color="grey"><v-icon color="#ffffff">fab fa-linkedin</v-icon></v-btn>
    <v-btn :href="getShareLinkXing()" tile class="ma-2" min-width="36" width="36" height="36" color="grey"><v-icon color="#ffffff">fab fa-xing</v-icon></v-btn>
    <v-btn :href="getShareLinkTwitter()" tile class="ma-2" min-width="36" width="36" height="36" color="grey"><v-icon color="#ffffff">fab fa-twitter</v-icon></v-btn>
    <v-btn v-show="mailSubject != null && mailText != null && mailSubject != '' && mailText != ''" :href="getShareLinkMail()" tile class="ma-2" min-width="36" width="36" height="36" color="grey"><v-icon color="#ffffff">fas fa-envelope</v-icon></v-btn>
  </div>
</template>

<script>
export default {

  props: {
    mailSubject: String,
    mailText: String,
    headline: {
      type: String,
      default: function(){
        return this.$t("share");
      }
    },
  },

  mounted(){
    if(this.headline == null || this.headline == undefined || this.headline == ""){
      this.headline = this.$t("share");
    }
    console.log(this.headline);
  },

  methods: {
    getShareLinkMail(){
      return "mailto:?subject=" + this.mailSubject + " &body=" + this.mailText;
    },

    getShareLinkXing(){
      return "https://www.xing.com/social_plugins/share/new?sc_p=xing-share&url=" + window.location.href;
    },

    getShareLinkTwitter(){
      return "http://twitter.com/home/?status=" + window.location.href;
    },  
  }
}
</script>
