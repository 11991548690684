<template>
  <div>
    <div class="side-background" style="background-image: url('/static/img/Background-CRMSystem.jpg')">
      <div class="side-background-foreground">
        <div class="container-fluid p-0">
          <div class="row">
            <div class="col-12 p-0">
              <div class="login-card register">
                <LanguagePicker @change="setLanguage" />
                <div class="theme-form login-form">
                  <div class="logo-wrapper d-flex justify-content-center"><img class="img-fluid" src="/static/images/logo/logo-crmsystem-3c.png" alt=""></div>
                  <div class="row">

                    <div class="form-group mb-0 pb-0 col-12 col-xl-6">
                      <label>{{$t("firstname")}}*</label>
                      <input class="form-control" type="text" v-model="user.firstname">
                    </div>
                    <div class="form-group mb-0 pb-0 col-12 col-xl-6">
                      <label>{{$t("lastname")}}*</label>
                      <input class="form-control" type="text" v-model="user.lastname">
                    </div>
                    <div class="form-group mb-0 pb-0 col-12 col-xl-6">
                      <label>{{$t("email")}}*</label>
                      <input class="form-control" type="email" v-model="user.email">
                    </div>
                    <div class="form-group mb-0 pb-0 col-12 col-xl-6">
                      <label>{{$t("password")}}*</label>
                      <input class="form-control" type="password" v-model="user.password">
                    </div>
                    <div class="form-group mb-0 pb-0 col-12 col-xl-6">
                      <label>{{$t("company")}}</label>
                      <input class="form-control" type="text" v-model="user.companyName">
                    </div>
                    <div class="form-group mb-0 pb-0 col-12 col-xl-6">
                      <label>{{$t("taxNumber")}}</label>
                      <input class="form-control" type="text" v-model="user.taxNumber">
                    </div>
                    <div class="form-group mb-0 pb-0 col-12 col-xl-6">
                      <label>{{$t("country")}}*</label>
                      <v-autocomplete class="mt-0 pt-0" v-model="user.country" :items="countries"
                        item-text="name" item-value="name" append-icon="" hide-details >
                        <template slot="item" slot-scope="data">
                          {{ data.item }}
                        </template>
                        <template slot='selection' slot-scope="data">
                          {{ data.item }}
                        </template>
                      </v-autocomplete>
                    </div>
                    <div class="form-group mb-0 pb-0 col-9 col-xl-8">
                      <label>{{$t("street")}}*</label>
                      <input class="form-control" type="text" v-model="user.street">
                    </div>
                    <div class="form-group mb-0 pb-0 col-3 pl-0 col-xl-4">
                      <label>{{$t("number")}}*</label>
                      <input class="form-control" type="text" v-model="user.hnr">
                    </div>
                    <div class="form-group mb-0 pb-2 col-4 col-xl-5">
                      <label>{{$t("postal code")}}*</label>
                      <input class="form-control" type="text" v-model="user.zip">
                    </div>
                    <div class="form-group mb-0 pb-2 col-8 col-xl-7">
                      <label>{{$t("city")}}*</label>
                      <input class="form-control" type="text" v-model="user.city">
                    </div>
                    <!-- <div class="form-group mb-4 pb-0">
                      <label>{{$t("image")}}*</label>
                      <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon size="16" v-on="on">fa fa-info-circle</v-icon>
                          </template>
                          <span>{{ $t("register_image_explanation") }}</span><br>
                        </v-tooltip>
                      <Upload :label="''" v-model="user.picture" />
                    </div> -->
                  </div>
                  <div class="form-group mb-0 pb-0">
                    <label style="text-transform: none">
                      <input class="align-middle" type="checkbox" v-model="acceptPrivacy">
                      {{ $t("accept_privacy_policy.text1") }} <a style='text-decoration: underline;' href="/privacy-policy" target="_blank">{{ $t("accept_privacy_policy.link") }}</a> {{ $t("accept_privacy_policy.text2") }}*
                    </label>
                  </div>
                  <div class="form-group mb-0 pb-0">
                    <label style="text-transform: none">
                      <input class="align-middle" type="checkbox" v-model="acceptTerms"> 
                      {{ $t("accept_general_terms.text1") }} <a style='text-decoration: underline;' href="/terms-of-use" target="_blank">{{ $t("accept_general_terms.link") }}</a> {{ $t("accept_general_terms.text2") }}*
                      </label>
                  </div>
                  <div class="form-group mb-0 pb-0">
                    <label style="text-transform: none">
                      <input class="align-middle" type="checkbox" v-model="user.newsletter">
                      {{$t("accept_newsletter")}}
                    </label>
                  </div>


                  <div class="form-group pt-3">
                    <button class="btn btn-primary-light btn-block ml-auto mr-auto mt-3" @click="register()"><a
                        href="javascript:void(0)">{{$t("register")}}</a></button>
                  </div>
                  <!-- <p>Don't have account?<a class="ms-2" href="log-in.html">Create Account</a></p> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  props: {
    routingTarget: String,
  },


  data() {
    return {
      showPassword: false,
      acceptTerms: false,
      acceptPrivacy:false,
      newsletter:false,

      user: {
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        companyName: "",
        country: "",
        street: "",
        hnr: "",
        zip: "",
        city: "",
        picture: "",
        taxNumber: "",
        newsletter: false,
      },

      countries: [],

      password_repeat: "",

      credentials: {
        mail: "",
        password: ""
      },

      mailRegex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      zipRegex: /^[0-9]*$/
    };
  },

  mounted() {
    this.$axios.checkBearer().then(this.onLoggedIn).catch(this.discardError);
    this.fetchCountries();
  },

  methods: {
    _validate(){   
      if(this.user.firstname == ""){
        this.$noty.error(this.$t("empty_value", { name: this.$t("firstname") }));
        return false;
      }
      if(this.user.lastname == ""){
        this.$noty.error(this.$t("empty_value", { name: this.$t("lastname") }));
        return false;
      }
      if(this.user.email == "" || !this.mailRegex.test(String(this.user.email).toLowerCase())){
        this.$noty.error(this.$t("empty_value", { name: this.$t("email") }));
        return false;
      }
      if(this.user.password == ""){
        this.$noty.error(this.$t("empty_value", { name: this.$t("password") }));
        return false;
      }
      if(this.user.country == ""){
        this.$noty.error(this.$t("empty_value", { name: this.$t("country") }));
        return false;
      }
      if(this.user.street == ""){
        this.$noty.error(this.$t("empty_value", { name: this.$t("street") }));
        return false;
      }
      if(this.user.hnr == ""){
        this.$noty.error(this.$t("empty_value", { name: this.$t("number") }));
        return false;
      }
      if(this.user.zip == "" || this.user.zip == null){
        this.$noty.error(this.$t("empty_value", { name: this.$t("zip") }));
        return false;
      }
      if(!this.zipRegex.test(String(this.user.zip).toLowerCase())){
        this.$noty.error(this.$t("invalid_value", { name: this.$t("zip") }));
        return false;
      }
      if(this.user.city == ""){
        this.$noty.error(this.$t("empty_value", { name: this.$t("city") }));
        return false;
      }
      // if(this.user.picture == ""){
      //   this.$noty.error(this.$t("empty_value", { name: this.$t("image") }));
      //   return false;
      // }
      if(!this.acceptPrivacy){
        this.$noty.error(this.$t('error_accept_privacy'));
        return false;
      }
      if(!this.acceptTerms){
        this.$noty.error(this.$t('error_accept_terms'));
        return false;
      }
      console.log(this.user);
      return true;
    },
    register() {
      if(!this._validate()) return;
      var _this = this;
      progressIndicator.hidden = false;
      this.$axios
        .post("/api/user/register", _this.user)
        .then(function (response) {
          _this.$noty.success(_this.$t("register_success"));
          _this.$router.push("login");
          _this.$forceUpdate();
        })
        .catch(_this.onError)
        .finally(() => {
          progressIndicator.hidden = true;
        });
    },
    setLanguage(shortForm) {
      if(shortForm == null) return;
      if(!window.location.href.includes('?')){
        window.location.href = window.location.href + "?lang=" + shortForm; 
      }else{
        window.location.href = window.location.href + "&lang=" + shortForm;
      }
    },
    fetchCountries() {
      var _this = this;
      this.$axios
        .get("/api/language/countries")
        .then(function (response) {
          _this.countries = response.data;
          _this.$forceUpdate();
        })
        .catch(_this.onError);
    },
    onError(err) {
      if (
        err.response != null &&
        err.response.data != null
      ) {
        this.$noty.error(err.response.data);
      } else {
        console.error(err);
      }
    },
    logError(error) {
      console.log(error);
    },
    discardError(error) { }
  },
};
</script>

<style>



</style>