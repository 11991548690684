<template>
  <div>
    <v-dialog
        v-model="value"
        activator="parent"
        width="500"
      >
        <v-card flat class="dialog-card">
          <v-card-title>
            {{ headline }}
          </v-card-title>
          <v-card-text>
            {{ text }}
          </v-card-text>
          <v-card-actions>
            <v-btn color="danger" block @click="closeDialog()">{{ $t("cancel") }}</v-btn>
            <v-btn color="primary" block @click="emitAction()">{{ actionButtonDesignation }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </div>
</template>

<script>
export default {

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    headline: {
      type: String,
      default: function(){
        return "";
      }
    },
    text: {
      type: String,
      default: function(){
        return "";
      }
    },
    actionButtonDesignation: {
      type: String,
      default: function(){
        return this.$t("confirm");
      }
    },
  },

  data() {
    return {
    }
  },

  mounted(){
    
  },

  methods: {
    closeDialog(){
      this.value = false;
      this.$emit("input", false);
    },
    emitAction(){
      this.value = false;
      this.$emit("input", false);
      this.$emit("actionClicked");
    },
  }

}
</script>

<style>
.v-dialog{
  background-color: white !important;
}

.v-card__actions{
  display: grid;
  grid-template-columns: auto auto;
}
</style>