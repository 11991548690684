<template>
<div>
  <div >
    <div class="header-image" style="background: url('/static/img/default_header.jpg')">
      <div class="headline"><h1>{{ $t("imprint") }}</h1></div>
    </div>
  </div>
  <div>
    <div class="row align-items-start">
      <!-- Left Area -->
      <div class="col-xl-8 px-8 pr-sm-16">        
        <div class="px-md-4 mx-0 pa-0 text-content">
            <h3>Name des Unternehmens</h3><br>
            <span>Name der GmbH</span><br>
            <span>Straße Hnr</span><br>
            <span>PLZ Ort</span><br>
            <span>Deutschland</span><br>
            <br>
            <span>Tel.: +49 12345 6789</span><br>
            <span>E-Mail: <a class="font-grey" href="mailto:info@codeguides.de">info@codeguides.de</a></span><br>
            <br>
            
        </div>
      </div>
      <!-- Right Area -->
      <!-- <div class="col-xl-4 pl-8 row">
        <div class="col-xl-12 right-side-block">
          <h3>{{ $t("actions") }}</h3>
          <div class="right-side divider"></div>
          <div class="mt-6"></div>
          <v-btn @click="$routerBack()" outlined depressed tile class="cancelbutton mr-2 mb-2">{{ $t("back") }}</v-btn>
          <v-btn @click="bookingRequestClicked()" outlined depressed tile class="savebutton mr-2 mb-2">{{ $t("booking_request") }}</v-btn>
        </div>
        <Contact />
        <Share :headline="$t('share_training')" :mailSubject="$t('share_mail_subject', {name: training.designationsMap[$locale]})" :mailText="getShareMailText()" />
      </div> -->
    </div>
  </div>
</div>
</template>

<script>


export default {


  data() {
    return {

    };
  },

  watch: {
    
  },

  computed: {
    
  },

  mounted() {
  },

  methods: {
    onError(err) {
      if (
        err.response != null &&
        err.response.data != null &&
        err.response.data.message != null
      ) {
        this.$noty.error(err.response.data.message);
      } else {
        console.error(err);
      }
    },


    getShareMailText(){
      return this.$t('share_mail_content', {link: window.location.href});
    }
  },
};
</script>
