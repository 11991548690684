<template>
  <div>
    <div class="container-fluid">
      <div class="page-header">
        <div class="row">
          <div class="col-sm-6">
            <h3>{{ $t("user management") }}</h3>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a>{{ $t("management") }}</a>
              </li>
              <li class="breadcrumb-item active">
                {{ $t("user management") }}
              </li>
            </ol>
          </div>
          <div class="col-sm-6">
            <!-- Bookmark Start-->
            <div class="bookmark">
              <ul>
                <li>
                  <a
                    href="javascript:void(0)"
                    data-container="body"
                    data-bs-toggle="popover"
                    data-placement="top"
                    title=""
                    data-original-title="Tables"
                    ><i data-feather="inbox"></i
                  ></a>
                </li>
                <li>
                  <a
                    href="javascript:void(0)"
                    data-container="body"
                    data-bs-toggle="popover"
                    data-placement="top"
                    title=""
                    data-original-title="Chat"
                    ><i data-feather="message-square"></i
                  ></a>
                </li>
                <li>
                  <a
                    href="javascript:void(0)"
                    data-container="body"
                    data-bs-toggle="popover"
                    data-placement="top"
                    title=""
                    data-original-title="Icons"
                    ><i data-feather="command"></i
                  ></a>
                </li>
                <li>
                  <a
                    href="javascript:void(0)"
                    data-container="body"
                    data-bs-toggle="popover"
                    data-placement="top"
                    title=""
                    data-original-title="Learning"
                    ><i data-feather="layers"></i
                  ></a>
                </li>
                <li>
                  <a href="javascript:void(0)"
                    ><i class="bookmark-search" data-feather="star"></i
                  ></a>
                  <form class="form-inline search-form">
                    <div class="form-group form-control-search">
                      <input type="text" placeholder="Search.." />
                    </div>
                  </form>
                </li>
              </ul>
            </div>
            <!-- Bookmark Ends-->
          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="mt-10 col-md-12 p-0 project-list">
        <div class="card">
          <div class="row">
            <div class="col-md-6 p-0"></div>
            <div class="col-md-6 p-0">
              <div class="form-group mb-0 me-0"></div>
              <a
                class="btn btn-primary-light"
                @click="$router.push('/create-user')"
              >
                <plus-square-icon class="custom-class"></plus-square-icon
                >{{ $t("create_new_user") }}</a
              >
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      v-show="$rights.includes('TENANT_INDEPENDENCE')"
                    >
                      {{ $t("tenant") }}
                    </th>
                    <th scope="col">{{ $t("name") }}</th>
                    <th scope="col">{{ $t("email") }}</th>
                    <th scope="col">{{ $t("roles") }}</th>
                    <th scope="col">{{ $t("actions") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="user in users" :key="user.id">
                    <td v-show="$rights.includes('TENANT_INDEPENDENCE')">
                      {{ user.tenant != null ? user.tenant.name : "" }}
                    </td>
                    <td><a class="link" @click="$router.push('/edit-user?userId=' + user.id)">{{ user.firstname }} {{ user.lastname }}</a></td>
                    <td>{{ user.email }}</td>
                    <td>{{ user.allRoles }}</td>
                    <td>
                      <button
                        class="btn btn-light mr-2"
                        @click="$router.push('/edit-user?userId=' + user.id)"
                      >
                        <i class="fa fa-pencil"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      style="
        position: absolute;
        bottom: 100px;
        left: 50%;
        height: 40px;
        width: 40px;
      "
      v-intersect.quiet="infiniteScrolling"
    ></div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
import pagination from "./custom/pagination.vue";
import {
  MapPinIcon,
  PlusSquareIcon,
  ClipboardIcon,
  FileTextIcon,
  TrashIcon,
  EditIcon,
} from "vue-feather-icons";

export default {
  components: {
    MapPinIcon,
    PlusSquareIcon,
    ClipboardIcon,
    FileTextIcon,
    TrashIcon,
    EditIcon,
  },

  data() {
    return {
      page: 1,
      totalPages: 1,
      ElementPerPage: 50,
      sort: null,
      order: true,
      routes: {
        users: "/api/user",
        tenants: "/api/tenant",
        filter: "/api/users/search/",
      },
      search: "",
      convertedTenants: [],
      tenantFilter: null,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      ],
      rules: [(value) => !!value || this.$t("required")],
      users: [],
      roles: [],
      createUserPopup: false,
      menu: false,
      createmode: true,

      // Personal Data
      me: null,
    };
  },

  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },

  mounted() {
    this.fetchUsers();
    if (this.$rights.includes("TENANT_INDEPENDENCE")) {
      this.fetchTenants();
    }
  },

  methods: {
    infiniteScrolling(entries, observer, isIntersecting) {
      if (isIntersecting) {
        setTimeout(() => {
          var _this = this;
          if (this.page < this.totalPages) {
            this.page++;
            this.$axios
              .get(this.routes.users, {
                params: {
                  sort: this.sort,
                  order: this.order ? "ASC" : "DESC",
                  page: this.page - 1,
                  size: this.ElementPerPage,
                },
              })
              .then(function (response) {
                if (response.data.content.length > 1) {
                  response.data.content.forEach((item) =>
                    _this.users.push(item)
                  );
                  _this.totalPages = response.data.totalPages;
                }
              })
              .catch(this.onError);
          }
        }, 500);
      }
    },
    previousPage() {
      this.page = this.page - 1;
      this.fetchUsers();
    },
    nextPage() {
      this.page = this.page + 1;
      this.fetchUsers();
    },
    onError(err) {
      if (
        err.response != null &&
        err.response.data != null &&
        err.response.data.message != null
      ) {
        this.$noty.error(err.response.data.message);
      } else {
        console.error(err);
      }
    },
    fetchUsers() {
      var _this = this;
      this.$axios
        .get(this.routes.users, {
          params: {
            search: _this.search,
            tenant: _this.tenantFilter,
            sort: _this.sort,
            order: _this.order ? "ASC" : "DESC",
            page: _this.page - 1,
            size: _this.ElementPerPage,
          },
        })
        .then(function (response) {
          _this.users = response.data.content;
          _this.totalPages = response.data.totalPages;
          _this.$forceUpdate();
        })
        .catch(this.onError);
    },
    fetchTenants() {
      var _this = this;
      (this.tenants = []),
        this.$axios
          .get(this.routes.tenants)
          .then(function (response) {
            const tenants = response.data.content;
            _this.tenants.push({
              id: -1,
              name: _this.$t("all_tenants"),
            });
            for (let i = 0; i < tenants.length; i++) {
              const tenant = tenants[i];
              _this.tenants.push(tenant);
            }
            _this.tenantFilter = _this.tenants[0].id;
            _this.$forceUpdate();
          })
          .catch(this.onError);
    },
    updatePage() {
      this.fetchUsers();
    },
    updateVisibleUsers() {
      var _this = this;
      this.users = this.users.slice(
        (_this.page = _this.ElementPerPage),
        (_this.page = _this.ElementPerPage) + _this.ElementPerPage
      );

      if (this.users.length == 0 && this.page > 0) {
        this.updatePage(this.page - 1);
      }
    },
    createUser() {
      var _this = this;

      var saveUser = {};
      Object.assign(saveUser, this.editedUser);

      saveUser.tenantId = saveUser.customer != null ? saveUser.customer.id : -1;
      saveUser.username = saveUser.mail;

      this.$axios
        .post(this.routes.users, saveUser)
        .then(function (response) {
          _this.$noty.success(_this.$t("user_created"));
          var user = response.data;
          if (
            _this.editedUser.role != null &&
            _this.editedUser.role.id != null
          ) {
            _this.addRoleToUser(user.id, _this.editedUser.role.id);
          }
          _this.addRightsToUser(user.id, _this.editedUser.rights);
          _this.resetEditedUser();
          _this.createUserPopup = false;
          _this.page = 1;
          _this.fetchUsers();
        })
        .catch(this.onError);
    },
    editUser() {
      var _this = this;

      var saveUser = {};
      Object.assign(saveUser, this.editedUser);

      saveUser.tenantId = saveUser.customer != null ? saveUser.customer.id : -1;
      saveUser.username = saveUser.mail;

      this.$axios
        .put(this.routes.users, saveUser)
        .then(function (response) {
          var user = response.data;
          _this.$noty.success(_this.$t("user_updated"));
          if (_this.editedUser.role != null && _this.editedUser.role != null) {
            _this.addRoleToUser(user.id, _this.editedUser.role.id);
          }
          _this.addRightsToUser(user.id, _this.editedUser.rights);
          _this.resetEditedUser();
          _this.createUserPopup = false;
          _this.fetchUsers();
        })
        .catch(this.onError);
    },
    deleteUser(userid) {
      var _this = this;
      this.$axios
        .delete("api/users/" + userid)
        .then(function (response) {
          _this.$noty.success(_this.$t("user_deleted"));
          _this.fetchUsers();
        })
        .catch(this.onError);
    },
    getJsonOptions() {
      const options = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      return options;
    },
  },
};
</script>

<style scoped>
.v-dialog > .v-card > .v-card__actions {
  padding: 8px 16px;
}
</style>

